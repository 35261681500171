import React, { useEffect, useState } from 'react';
import styles from './signatureControl.module.scss';
import SignatureCanvas from 'react-signature-canvas';
import SigIcon from '../icons/icon-signature.svg';
import { SignatureItem } from '../../models/schema/item';
import { useDispatch, useSelector } from 'react-redux';
import { applicationActionCreators } from '../../store/appState/actionCreators';
import { useParams } from 'react-router-dom';
import { SignatureItemResponse } from '../../models/submissions/itemResponse';
import { ItemType } from '../../models/itemTypes';
import { v4 as uuid } from 'uuid';
import { Page, PageMain } from '../core/page';
import { Button } from '../core/button';
import { RootState } from '../../store';

type Props = {
    item: SignatureItem;
    done: () => void;
    onBack: () => void;
}

type Params = {
    orderId: string;
    taskId: string;
};

export const SignatureControl = (props: Props) => {
    const sigCanvasRef = React.useRef<any>(null);
    const [ready, setReady] = React.useState(false);

    const params = useParams<Params>();
    const orderId = parseInt(params.orderId);
    const taskId = parseInt(params.taskId);

    const orgName = useSelector((state: RootState) => state.appState.orders[params.orderId].organisationName);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(applicationActionCreators.startTaskItem(orderId, taskId, props.item.id));
    }, []);

    const handleSave = () => {
        const base64Image = sigCanvasRef.current.getTrimmedCanvas().toDataURL('base64string').split(',')[1] as string;
        const signatureResponse: SignatureItemResponse = {
            Type: 'Inspections.SignatureItemResponse',
            itemType: ItemType.signature,
            image: {
                Type: 'Image',
                imageGuid: uuid(),
                createdUtc: new Date().toISOString(),
                data: base64Image,
            },
        };
        dispatch(applicationActionCreators.saveTaskItemAndUpdateProgress(orderId, taskId, props.item.id, signatureResponse));
        props.done();
    }

    const width = window.innerWidth > 528 ? 528 : window.innerWidth - 40;

    return (
        <Page title="Signature" onBack={props.onBack}>
            <PageMain>
            <div className={styles.content}>
                <img src={SigIcon} alt="" />
                <div className={styles.disclaimer}>
                    By signing below, I:

                    <ol>
                        <li>
                            confirm that the information I have provided is true and accurate;
                        </li>
                        <li>
                            acknowledge that Redbook Inspect Pty Ltd (trading as Olasio) is collecting my 
                            personal information and will handle it in accordance with its&nbsp;
                            <a href="https://www.redbookinspect.com.au/Privacy">Privacy Policy</a>, 
                            including by providing a copy of this information to {orgName}; and
                        </li>
                        <li>
                            {orgName} will handle your personal information in accordance with its own 
                            privacy policy.
                        </li>
                    </ol>
                </div>
                <div className={styles.sigTop}>
                    <label>Please sign in the box below</label>
                    {ready && <button
                        onClick={() => {
                            if (sigCanvasRef.current) {
                                setReady(false);
                                sigCanvasRef.current.clear();
                            }
                        }}
                    >
                        Clear
                    </button>}
                </div>
                <div className={styles.center}>
                    <div className={styles.sigBox}>
                        <SignatureCanvas
                            ref={sigCanvasRef}
                            penColor="black"
                            canvasProps={{ height: width/2.5, width }}
                            onEnd={() => {
                                if (sigCanvasRef.current) {
                                    if (!sigCanvasRef.current.isEmpty())
                                        setReady(true);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            </PageMain>
            <Button color="green" onClick={handleSave} disabled={!ready}>Done</Button>
        </Page>
    );
};
