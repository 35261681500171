import React, { useState } from 'react';
import { PhotoItem } from '../../models/schema/item';
import { PhotoItemResponse } from '../../models/submissions/itemResponse';
import { ImageUpload } from '../core/imageUpload';
import { useImageStatus } from '../../store/images/hooks'
import { Page, PageMain } from '../core/page';
import { Button } from '../core/button';

export const PhotoControl: React.FC<Props> = ({ item, onExit, onConfirm, response, readOnly }) => {
    const [image, setImage] = useState(response && response.image);

    const isValid = useImageStatus(image);

    const handleSubmit = () => {
        onConfirm({
            Type: 'Inspections.PhotoItemResponse',
            itemType: item.itemType,
            image,
        });
    };

    return (
        <Page title={item.display} onBack={onExit}>
            <PageMain>
                <ImageUpload
                    label={item.display}
                    images={image ? [image] : []}
                    itemId={item.id}
                    onChange={images => setImage(images[0])}
                    onDelete={() => setImage(undefined)}
                    readOnly={readOnly}
                />
            </PageMain>
            <Button color="green" onClick={handleSubmit} disabled={!isValid}>Confirm</Button>
        </Page>
    );
}

interface Props {
    inGroup?: boolean;
    item: PhotoItem;
    onExit: () => void;
    onConfirm: (data: PhotoItemResponse) => void;
    response?: PhotoItemResponse;
    readOnly?: boolean
}
