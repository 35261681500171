import React, { useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch, useLocation, useHistory } from 'react-router-dom';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Layout } from './components/layout';
import { Login } from './components/login';
import { LoginAuto } from './components/loginAuto';
import { InspectionMainView } from './components/inspectionMain';
import { TaskPage } from './components/taskPage';
import { InspectionItem } from './components/inspectionItem';
import { store, RootState } from './store';
import { Header } from './components/header';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './routing.scss';
import { Home } from './components/home';

export const App: React.FC = () => {
  return (
    <Provider store={store}>
        <BrowserRouter>
          <Content />
        </BrowserRouter>
    </Provider>
  );
};

// wrapped as a separate component in order to access store from <Provider />
const Content: React.FC = () => {
  const loggedIn = useSelector(
    (state: RootState) => state.appState.loginSuccess
  );

  const unloadHandler = (e: BeforeUnloadEvent) => {
    if (loggedIn) {
      e.preventDefault();
      e.returnValue = 'Are you sure? You will lose your unsubmitted data.';
      return 'Are you sure? You will lose your unsubmitted data.';
    }
  };

  useEffect(() => {
    // prevent reload warning during development
    if (process.env.NODE_ENV === 'development') return;

    window.addEventListener('beforeunload', unloadHandler);
    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [loggedIn]);

  const location = useLocation();
  const history = useHistory();

  const classNames = history.action === 'PUSH' ? 'push' : 'pop';

  /**
   * Notes on routing:
   *
   * We're keeping the Login routes outside of animated routes because atm
   * they are using the same 'splash' background, causing funny animations
   */

  return (
    <Layout>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/start/:hash" component={LoginAuto} />
        <Route path="*">
          <TransitionGroup
            childFactory={child => React.cloneElement(child, { classNames })}
          >
            <CSSTransition
              key={location.key}
              classNames={classNames}
              timeout={600}
            >
              <Switch location={location}>
                <Route
                  path="/order/:orderId/task/:taskId/item/:itemId"
                  component={InspectionItem}
                />
                <Route
                  path="/order/:orderId/task/:taskId"
                  component={TaskPage}
                />
                <Route path="/order/:orderId" component={InspectionMainView} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </Route>
      </Switch>
    </Layout>
  );
};
