import React, { useRef, useEffect } from 'react';
import styles from './sharedControl.module.scss';
import { LocationItem } from '../../models/schema/item';
import { LocationItemResponse } from '../../models/submissions/itemResponse';
import { ItemType } from '../../models/itemTypes';
import { Input } from '../core/input';
import { Page, PageMain } from '../core/page';
import { Button } from '../core/button';

export const LocationControl: React.FC<Props> = ({ item, onExit, onConfirm, response, orderId, taskId }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        initAutocomplete();
        return () => {
            // clean up places api autocomplete box
            const container = document.querySelector('.pac-container');
            if (container) container.remove()
        }
    },[])

    const initAutocomplete = () => {
        var options = {
            componentRestrictions: { country: 'au' }
        };

        if (inputRef.current) {
            var autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, options);

            autocomplete.addListener('place_changed', () => {
                var place = autocomplete.getPlace();
                let location = {
                    Id: 0,
                    name: place.formatted_address,
                    number: place.address_components?.[0].long_name ?? '',
                    street: place.address_components?.[1].long_name ?? '',
                    suburb: place.address_components?.[2].long_name ?? '',
                    state: place.address_components?.[4].long_name ?? '',
                    country: place.address_components?.[5].long_name ?? '',
                    postcode: place.address_components?.[6].long_name ?? '',
                    lat: place.geometry?.location.lat() ?? 0,
                    lng: place.geometry?.location.lng() ?? 0,
                };

                setStreet(`${location.number} ${location.street}`)
                setSuburb(location.suburb)
                setPostcode(location.postcode)
                setLatLong({
                    lat: location.lat,
                    lng: location.lng
                });
            });
        }
    }

    const submit = () => {
      const [streetNumber, streetAddress] = street.split(/ (.+)/);
        onConfirm({
            Type: "Inspections.LocationItemResponse",
            itemType: ItemType.location,
            site: unit,
            number: streetNumber,
            street: streetAddress,
            suburb,
            postcode,
            latitude: latLong ? latLong.lat : undefined,
            longitude: latLong ? latLong.lng : undefined
        });
    };

    const [unit, setUnit] = React.useState(response && response.site || '');
    const [street, setStreet] = React.useState(response && response.street && response.number ? `${response.number} ${response.street}` : '');
    const [latLong, setLatLong] = React.useState(response && response.latitude && response.longitude ? { lat: response.latitude, lng: response.longitude } : undefined);
    const [suburb, setSuburb] = React.useState(response && response.suburb || '');
    const [postcode, setPostcode] = React.useState(response && response.postcode || '');

    return (
        <Page title={item.display} onBack={onExit}>
            <PageMain>
                <Input
                    label="Unit, flat, etc. (optional)"
                    autoComplete="address-line2"
                    value={unit}
                    onChange={e => setUnit(e.target.value)}
                />
                <Input
                    ref={inputRef}
                    label="Street address"
                    placeholder=""
                    autoComplete="address-line1"
                    value={street}
                    onChange={e => setStreet(e.target.value)}
                />
                <div className={styles.doubleInputs}>
                    <Input
                        label="Suburb"
                        autoComplete="address-level2"
                        value={suburb}
                        onChange={e => setSuburb(e.target.value)}
                    />
                    <Input
                        label="Postcode"
                        autoComplete="postal-code"
                        value={postcode}
                        onChange={e => setPostcode(e.target.value)}
                    />
                </div>
            </PageMain>
            <Button color="green" onClick={submit} disabled={!(street && suburb && postcode)}>Confirm</Button>
        </Page>
    );
}

interface Props {
    item: LocationItem;
    onExit: () => void;
    onConfirm: (response: LocationItemResponse) => void;
    response?: LocationItemResponse;
    orderId: string;
    taskId: string;
}
