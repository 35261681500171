// source: https://en.wikipedia.org/wiki/Vehicle_identification_number#Example_Code
// --------------------------------------------------------------------------------

export const validateVin = (vin: string) => {
    if (vin.length !== 17) return false;
    vin = vin.toUpperCase();
    if (vin.indexOf('O') !== -1) return false;
    if (vin.indexOf('Q') !== -1) return false;
    if (vin.indexOf('I') !== -1) return false;

    return true;

    // The below is only consistent in North America
    //return get_check_digit(vin) === vin[8];
}

// rules for VIN stripping
// https://github.com/mikebuss/MTBBarcodeScanner/issues/27#issuecomment-352892442
export const adjustVin = (value: string) => {
    if (value.length === 18) {
        return value.substring(1);
    } else if (value.length === 19) {
        return value.substring(1, 18);
    } else if (value.length === 20) {
        return value.substring(2, 19);
    } else {
        return value; // unchanged
    }
}
