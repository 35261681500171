import React from 'react';
import { ImageUpload } from '../core/imageUpload';
import { PhotoItem } from '../../models/schema/item';
import { PhotoItemResponse } from '../../models/submissions/itemResponse';
import { ItemType } from '../../models/itemTypes';

type Props = {
  item: PhotoItem;
  response?: PhotoItemResponse;
  onChange: (response: PhotoItemResponse) => void;
  readOnly?: boolean;
};

export const GroupPhotoControl = (props: Props) => {
  // <ImageUpload /> accepts an array, PhotoItem is only a single image
  const images =
    props.response && props.response.image ? [props.response.image] : [];

  return (
    <ImageUpload
      label={props.item.display}
      images={images}
      itemId={props.item.id}
      onChange={newImages => {
        props.onChange({
          Type: 'Inspections.PhotoItemResponse',
          itemType: ItemType.photo,
          image: newImages[0],
        });
      }}
      onDelete={() => {
        props.onChange({
          Type: 'Inspections.PhotoItemResponse',
          itemType: ItemType.photo,
          image: undefined,
        });
      }}
      readOnly={props.readOnly}
    />
  );
};
