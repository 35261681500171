import React, { useState } from 'react';
import styles from './home.module.scss';
import { _apiUrl } from '../settings';
import { Button } from './core/button';

export const Home = () => {
    return (<div className={styles.spacing}>
        <div className={styles.main}>
            <div className={styles.topContent}>
                <div className={styles.caption}>
                    <div className={styles.centerBorder}> ___ </div>
                    <label className={styles.text}>
                        Data smarter, not harder.
                    </label>
                </div>
                <div className={styles.heading}>
                    <h1>
                        It’s never been this easy to <span className={styles.coloredWord}> collect</span> data
                    </h1>
                    <label>
                        Enhanced data services, digitally captured, and securely delivered.
                    </label>
                </div>
                <Button 
                    style={{ maxWidth: '330px', marginTop: '30px', marginLeft: 'auto', marginRight: 'auto' }} color="green" marginTop="30px"
                    onClick={() => {document.location.href = 'https://www.olasio.com/source';}}>
                        Learn More
                </Button>
            </div>
            <div className={styles.smartTasks}>
                <div className={styles.startTaskText}>
                    <h3>Our Smart Tasks</h3>
                    <label>Choose from an expansive list of comprehensive data collection task to best suit your business. Then send and receive smart tasks from your customised Olasio dashboard.</label>
                </div>

                <Button 
                    style={{ maxWidth: '330px', marginTop: '30px' }} color="blue" marginTop="30px"
                    onClick={() => {document.location.href = 'https://source.olasio.com/public/store';}}>
                        View Smart Task Store
                </Button>
            </div>
            <label className={styles.completeTaskText}>
                <b>Trying to start a requested task?</b><br />
                Open the task link Olasio sent you via text message or email on a device that has a camera and GPS location.
            </label>
        </div>
    </div>
    );
};