import { combineReducers, configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { applicationStateReducer } from './appState';
import imagesReducer from './images/imagesSlice';
import sharedDataReducer from './sharedData/sharedDataSlice';

const rootReducer = combineReducers({
  appState: applicationStateReducer,
  images: imagesReducer,
  sharedData: sharedDataReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
