import React from 'react';
import styles from './groupYesNoControl.module.scss';
import { Toggle } from '../core/toggle';
import { ImageUpload } from '../core/imageUpload';
import { YesNoItem } from '../../models/schema/item';
import { YesNoItemResponse } from '../../models/submissions/itemResponse';
import { ItemType } from '../../models/itemTypes';
import { Image } from '../../models/submissions/image';

type Props = {
  item: YesNoItem;
  response?: YesNoItemResponse;
  onChange: (response: YesNoItemResponse) => void;
  readOnly?: boolean;
};

export const GroupYesNoControl = (props: Props) => {
  const { item, response, readOnly, onChange } = props;
  
  const handleValueChange = (value: string) => {
    const responseImages = response ? response.images : [];

    // TODO: remove from store as well, or perhaps delay until user exits group
    // (handle at group level), in which case, also don't set an empty array
    // here and simply hide it until the user saves group responses

    // clear images if `noValue` is selected
    const images = value === item.noValue ? [] : responseImages;

    onChange({
      Type: 'Inspections.YesNoItemResponse',
      itemType: ItemType.yesNo,
      value,
      images,
    });
  };

  const handleImageAdd = (images: Image[]) => {
    onChange({
      Type: 'Inspections.YesNoItemResponse',
      itemType: ItemType.yesNo,
      value: item.yesValue,
      images,
    });
  };

  const handleImageDelete = (guid: string) => {
    onChange({
      Type: 'Inspections.YesNoItemResponse',
      itemType: ItemType.yesNo,
      value: item.yesValue,
      images: response!.images!.filter(image => image.imageGuid !== guid),
    });
  };

  const showImageUpload =
    item.photoDisplay && (response?.value === item.yesValue);

  return (
    <div className={styles.yesno}>
      <div className={styles.content}>
        <p>{item.display}</p>
        <div className={styles.toggle}>
          <Toggle
            values={[item.yesValue, item.noValue]}
            value={response && response.value}
            onChange={handleValueChange}
            readOnly={readOnly}
          />
        </div>
      </div>
      {showImageUpload && (
        <ImageUpload
          label={item.photoDisplay}
          images={response ? response.images! : []}
          onChange={handleImageAdd}
          onDelete={handleImageDelete}
          readOnly={readOnly}
          itemId={item.id}
          multi
        />
      )}
    </div>
  );
};
