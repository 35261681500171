import React, { useState } from 'react';
import { SignatureControl } from './items/signatureControl';
import { GpsControl } from './items/gpsControl';
import { Section } from '../models/schema';
import { ItemType } from '../models/itemTypes';

type Props = {
  section: Section;
  onExit: () => void;
  onDone: () => void;
};

export const ConfirmationSection = (props: Props) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const currentItem = props.section.items[currentItemIndex];

  const handleBack = () => {
    if (currentItemIndex === 0) {
      props.onExit();
    } else {
      setCurrentItemIndex(currentItemIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentItemIndex === props.section.items.length - 1) {
      props.onDone();
    } else {
      setCurrentItemIndex(currentItemIndex + 1);
    }
  };

  switch (currentItem.itemType) {
    case ItemType.signature:
      return <SignatureControl item={currentItem} onBack={handleBack} done={handleNext} />;
    case ItemType.gps:
      return <GpsControl item={currentItem} done={handleNext} onBack={handleBack} />;
    default:
      return <p>Item type not supported.</p>;
  }
};
