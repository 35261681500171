export const appFetch = async (url: string, method: string = 'GET', body?: string, headers?: Headers) => {
    if (!headers) {
        headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
    }

    return fetch(url, {
        method: method,
        //credentials: 'same-origin',
        headers: headers,
        //mode: 'no-cors',
        redirect: 'follow',
        cache: 'no-cache',
        body: body,
    });
};
