import React from 'react';
import styles from './infoStep.module.scss';

type Props = {
  image?: string;
  text?: string;
};

export const InfoStep: React.FC<Props> = props => (
  <div className={styles.info}>
    {props.image && <img src={props.image} alt="" />}
    {props.text && <p>{props.text}</p>}
    {props.children}
  </div>
);
