import React, { useState } from 'react';
import { MediaItem } from '../../models/schema/item';
import { MediaItemResponse } from '../../models/submissions/itemResponse';
import { ImageUpload } from '../core/imageUpload';
import { useImageStatus } from '../../store/images/hooks'
import { Page, PageMain } from '../core/page';
import { Button } from '../core/button';

export const MediaControl: React.FC<Props> = ({ item, onExit, onConfirm, response, readOnly }) => {
    const [media, setMedia] = useState(
        (response && response.media) ?
            {
                ...response.media,
                imageGuid: response.media.mediaGuid
            } :
            undefined
    );

    const isValid = useImageStatus(media);
    const handleSubmit = () => {
        onConfirm({
            Type: 'Inspections.MediaItemResponse',
            itemType: item.itemType,
            media: media
        });
    };

    return (
        <Page title={item.display} onBack={onExit}>
            <PageMain>
                <ImageUpload
                    label={item.display}
                    name={item.name}
                    images={media ? [(media as any)] : []}
                    itemId={item.id}
                    onChange={images => setMedia({
                        ...images[0],
                        mediaGuid: images[0].imageGuid
                    })}
                    onDelete={() => setMedia(undefined)}
                    readOnly={readOnly}
                    facingMode={item.facingMode}
                />
            </PageMain>
            <Button color="green" onClick={handleSubmit} disabled={!isValid}>Confirm</Button>
        </Page>
    );
}

interface Props {
    inGroup?: boolean;
    item: MediaItem;
    onExit: () => void;
    onConfirm: (data: MediaItemResponse) => void;
    response?: MediaItemResponse;
    readOnly?: boolean
}
