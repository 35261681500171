import React, { useEffect, useState } from 'react';
import { GpsItem } from '../../models/schema/item';
import { useDispatch, useSelector } from 'react-redux';
import { applicationActionCreators } from '../../store/appState/actionCreators';
import { useParams } from 'react-router-dom';
import { GpsItemResponse } from '../../models/submissions/itemResponse';
import { ItemType } from '../../models/itemTypes';
import { Loading } from '../core/loading';
import { getCurrentPosition } from '../../tools/geolocation';
import { Page } from '../core/page';
import { DevicePermissions } from '../devicePermissions';
import { RootState } from '../../store';

type Props = {
  item: GpsItem;
  done: () => void;
  onBack: () => void;
};

type Params = {
  orderId: string;
  taskId: string;
};

export const GpsControl = (props: Props) => {
  const params = useParams<Params>();
  const orderId = parseInt(params.orderId);
  const taskId = parseInt(params.taskId);

  const dispatch = useDispatch();

  const [noPermission, setNoPermission] = useState(false);
  
  useEffect(() => {
    dispatch(applicationActionCreators.startTaskItem(orderId, taskId, props.item.id));
    getPos();
  }, []);

  const getPos = () => {
    setNoPermission(false);
    getCurrentPosition().then(pos => {
      handleSave(pos.coords.latitude, pos.coords.longitude, pos.coords.accuracy);
    }).catch((reason: GeolocationPositionError) => {
      if (reason.PERMISSION_DENIED)
      {
        setNoPermission(true);
      }
      else {
        handleSave();
      }
    });
  }

  const handleSave = (latitude?: number, longitude?: number, accuracy?: number) => {
    const gpsResponse: GpsItemResponse = {
      Type: 'Inspections.GpsItemResponse',
      itemType: ItemType.gps,
      latitude,
      longitude,
      accuracy,
      dateTimeUtc: new Date().toISOString(),
    };
    dispatch(
      applicationActionCreators.saveTaskItemAndUpdateProgress(
        orderId,
        taskId,
        props.item.id,
        gpsResponse
      )
    );
    props.done();
  };

  return (
    <Page title="Location">
        {noPermission ?
          <DevicePermissions
            onBack={props.onBack}
            locationRequested={true}
            locationAccuracyRequested={false}
            cameraRequested={false}
            onGranted={getPos} /> :
          <Loading />
        }
    </Page>
  );
};
