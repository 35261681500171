import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Button } from './core/button';
import styles from './taskPage.module.scss';
import { Page, PageMain } from './core/page';
import { getCurrentPosition } from '../tools/geolocation';
import { requestCameraPermission } from '../tools/cameraPermission';
import { Loading } from './core/loading';
import { _locationAccuracy } from '../settings';

type Props = {
  cameraRequested: boolean
  locationRequested: boolean
  onGranted?: () => void;
  locationAccuracyRequested: boolean;
  onBack?: () => void;
};

type Params = {
  orderId: string;
  taskId: string;
};

export const DevicePermissions = (props: Props) => {
  const [locationGranted, setLocationGranted] = useState(false);
  const [cameraGranted, setCameraGranted] = useState(false);
  const [locationAccuracyGranted, setLocationAccuracyGranted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props.cameraRequested)
      setCameraGranted(true);
    if (!props.locationRequested)
      setLocationGranted(true);
    if (!props.locationAccuracyRequested)
      setLocationAccuracyGranted(true);
  }, [props.cameraRequested, props.locationAccuracyRequested, props.locationRequested]);

  const retry = async () => {
    console.log('Retry');
    if (!locationGranted || !locationAccuracyGranted)  {
      getCurrentPosition(0).then(pos => {
        setLocationGranted(true);

        if (pos.coords.accuracy <= _locationAccuracy) {
          setLocationAccuracyGranted(true);
        }
      }).catch((err) => {
        setLocationGranted(false);
      });
    }

    if (!cameraGranted) {
      requestCameraPermission()
        .then(response => {
          if (response) {
            setCameraGranted(response);
          }
          else {
            setCameraGranted(false);
          }
        }).catch((err) => {
          setCameraGranted(false);
        });
    }

    if (locationGranted && cameraGranted) {
      if (locationAccuracyGranted)
        props.onGranted?.();
      else {
        setLoading(true);

        setTimeout(() => {
          getCurrentPosition(0)
            .then(pos => {
              setLoading(false);
              if (pos.coords.accuracy <= _locationAccuracy) {
                props.onGranted?.();
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              setLocationGranted(false);
              setLocationAccuracyGranted(false);
            });
        }, 3000);
      }
    }
  };

  return <Page title="Permissions" onBack={props.onBack}>
    <PageMain>
      <main className={styles.contentWrapper}>
        {loading ?
          <div className={styles.content}>
            <p style={{ textAlign: 'center' }}>Re-trying location capture. This could take up to a minute.</p>
            <Loading />
          </div> :
          <div className={styles.content}>
            <div className={styles.permissionHeading}>Enable location and camera access to continue</div>
            <div className={styles.permissionText}>
              <p>
                To proceed with Olasio, grant permission for camera and location access. This is essential for capturing live photos of your asset and confirming its location to authenticate details for the finance provider.
              </p>
              <p>
                Ensure that your device's camera and location access is enabled on your phone's web browser:
              </p>
              <p>
                <ul>
                  <li>
                    iPhone: Use Safari for the best experience.
                  </li>
                  <li>
                    Android/Samsung: Use Chrome for the best experience.
                  </li>
                </ul>
              </p>
              <div className={styles.retryIns}>Once you've enabled access, press "Retry" to begin.</div>
            </div>
            <div className={styles.permissionButton}>
              <Button color='green' onClick={retry}>Retry</Button>
            </div>
            {(!props.locationRequested || locationGranted) && (!props.cameraRequested || cameraGranted) ?
              '' :
              <Fragment>
                <div className={styles.permissionDesc}>Trouble shooting</div>
                <div className={styles.permissionDetail}>
                  <p>
                    If you need guidance on enabling permissions on your device, please visit the <a href='https://olasio.zendesk.com/hc/en-au/categories/15102020550937-Help-with-Devices' target='blank'>help centre.</a>
                  </p>
                  <p>
                    Alternatively <a className={styles.contactSupport} href="mailto:help@olasio.com?cc=">contact support</a> for help.
                  </p>
                  <p>
                    Please note, you must open the link Olasio sent you via text message on a device that has GPS location sharing 
                    such as your mobile phone. If your device does not have this feature, please forward the link to another device 
                    that does.
                  </p>
                </div>
              </Fragment>}
          </div>
        }
      </main>
    </PageMain>
  </Page >
}
