function pascalToCamel(key: string) {
  const pascalRegex = /^([A-Z])([a-z]+)/;
  if (pascalRegex.test(key)) {
    return key[0].toLowerCase() + key.slice(1);
  }
  return key;
}

// transforms PascalCase object keys into camelCase, eg. ItemType -> itemType
function camelize(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(element => camelize(element));
  } else if (typeof obj === 'object') {
    const result: any = {};
    for (const key in obj) {
      const value = obj[key];
      result[pascalToCamel(key)] = camelize(value);
    }
    return result;
  }
  return obj; // is primitive type
}

export { camelize };
