import { _apiUrl } from '../settings';
import { camelize } from '../tools/objectTransform';
import { Order } from '../models/schema';

export const getOrder = async (email: string, hash: string) => {
  const url = `${_apiUrl}/jobs`;
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ Hash: hash, Email: email }),
  });
  const data = await res.json();
  return camelize(data) as Order;
};

export const getEmail = async (hash: string) => {
  const url = `${_apiUrl}/jobs/prefill/${hash}`;
  const res = await fetch(url);
  // TODO: error handle
  const data = await res.json();
  const email = data.email.replace(/"/gi, '');
  return email as string;
};
