import React from 'react';
import styles from './sharedControl.module.scss';
import { Select } from '../core/select';
import { MonthOptions, range } from '../../tools/selectOptions';
import { DateItem, DateGranularity } from '../../models/schema/item';
import { DateItemResponse } from '../../models/submissions/itemResponse';
import { ItemType } from '../../models/itemTypes';

type Props = {
  item: DateItem;
  response?: DateItemResponse;
  onChange: (response: DateItemResponse) => void;
  readOnly?: boolean;
};

type DateChange = {
  day?: number;
  month?: number;
  year?: number;
};

export const GroupDateControl = (props: Props) => {
  const years = range(1900, 2100);

  const dayNumber = props.response
    ? getDayNumber(props.response.month, props.response.year)
    : getDayNumber();

  const days = range(1, dayNumber);

  const handleChange = (change: DateChange) => {
    const { response } = props;

    props.onChange({
      Type: 'Inspections.DateItemResponse',
      itemType: ItemType.date,
      year: change.year || (response ? response.year : undefined),
      month: change.month || (response ? response.month : undefined),
      day: change.day || (response ? response.day : undefined),
    });
  };

  switch (props.item.granularity) {
    case DateGranularity.Year:
      return (
        <Select
          label={props.item.display}
          required
          value={props.response ? props.response.year : ''}
          onChange={e => handleChange({ year: parseInt(e.target.value) })}
          disabled={props.readOnly}
        >
          <option value="" disabled>
            Year
          </option>
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      );

    case DateGranularity.Month:
      return (
        <div className={styles.doubleInputs}>
          <Select
            label={props.item.display}
            required
            value={props.response ? props.response.month : ''}
            onChange={e => handleChange({ month: parseInt(e.target.value) })}
            disabled={props.readOnly}
          >
            <option value="" disabled>
              Month
            </option>
            {MonthOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </Select>
          <Select
            required
            value={props.response ? props.response.year : ''}
            onChange={e => handleChange({ year: parseInt(e.target.value) })}
            disabled={props.readOnly}
          >
            <option value="" disabled>
              Year
            </option>
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </div>
      );
    case DateGranularity.Date:
    default:
      return (
        <>
          <label className={styles.label}>{props.item.display}</label>
          <div className={styles.tripleInputs}>
            <Select
              required
              value={props.response ? props.response.day : ''}
              onChange={e => handleChange({ day: parseInt(e.target.value) })}
              disabled={props.readOnly}
            >
              <option value="" disabled>
                Day
              </option>
              {days.map(day => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </Select>
            <Select
              required
              value={props.response ? props.response.month : ''}
              onChange={e => handleChange({ month: parseInt(e.target.value) })}
              disabled={props.readOnly}
            >
              <option value="" disabled>
                Month
              </option>
              {MonthOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </Select>
            <Select
              required
              value={props.response ? props.response.year : ''}
              onChange={e => handleChange({ year: parseInt(e.target.value) })}
              disabled={props.readOnly}
            >
              <option value="" disabled>
                Year
              </option>
              {years.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Select>
          </div>
        </>
      );
  }
};

const getDayNumber = (month?: number, year?: number) => {
  switch (month) {
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    case 2:
      if (!year) return 29;
      const isLeap = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      return isLeap ? 29 : 28;
    default:
      return 31;
  }
};
