import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applicationActionCreators } from '../store/appState/actionCreators';
import styles from './header.module.scss';
import {RootState} from '../store'
import { useHistory } from 'react-router-dom';

export const Header: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loggedIn = useSelector((state: RootState) => state.appState.loginSuccess);

    return (
        <div className={styles.header}>
            {(loggedIn && document.location.pathname && document.location.pathname != "/") && (
                <button
                    className={styles.button}
                    onClick={() => {
                        history.push('/');
                        dispatch(applicationActionCreators.logout());
                    }}
                >
                    Logout
                </button>
            )}
        </div>
    );
}
