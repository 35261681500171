// promise wrapper for geolocation
export function getCurrentPosition(overrideTimeout?: number) {
    return new Promise<GeolocationPosition>((resolve, reject) => {
        // allows disabling of geolocation in dev
        if (process.env.REACT_APP_NO_GEO) {
            reject(new Error('Geolocation disabled by environment variable.'));
        }

        if ('geolocation' in navigator) {      
            navigator.geolocation.getCurrentPosition(
                resolve,
                reject,
                {
                    enableHighAccuracy: true,
                    timeout: 20000,
                    maximumAge: overrideTimeout == undefined ? 30000 : overrideTimeout,
                }
            );
        } else {
            reject(new Error('Geolocation is not available.'));
        }
    });
}
