import React from 'react';
import { ImageUpload } from '../core/imageUpload';
import { MediaItem, MediaType } from '../../models/schema/item';
import { MediaItemResponse } from '../../models/submissions/itemResponse';
import { ItemType } from '../../models/itemTypes';
import { Page } from '../core/page';

type Props = {
  item: MediaItem;
  response?: MediaItemResponse;
  onChange: (response: MediaItemResponse) => void;
  readOnly?: boolean;
};

export const GroupMediaControl = (props: Props) => {
  const allowableType = props.item.allowableTypes[0];
  var mediaList;

  switch (allowableType) {
    case MediaType.Image:
      if (props.response) {
        if (props.response.media)
          mediaList = [props.response.media];
        else if (props.response.images)
          mediaList = props.response.images;
        else if (props.response.image)
          mediaList = [props.response.image];
        else
          mediaList = [];
      }
      else
        mediaList = [];
  }

  switch (allowableType) {
    case MediaType.Image:
      return (
        <ImageUpload
          label={props.item.display}
          name={props.item.name}
          images={mediaList}
          itemId={props.item.id}
          noUpload={!props.item.allowsFromLibrary}
          onChange={newImages => {
            props.onChange({
              Type: 'Inspections.MediaItemResponse',
              itemType: ItemType.media,
              image: newImages[0],
            });
          }}
          onDelete={() => {
            props.onChange({
              Type: 'Inspections.MediaItemResponse',
              itemType: ItemType.media,
              image: undefined,
            });
          }}
          readOnly={props.readOnly}
        />
      );
    default:
      return <Page>Media type not supported</Page>
  }
};
