import * as React from 'react';

export class Layout extends React.Component<LayoutProps> {
    render() {
        return (
            <div className='splash-page'>
                <div className='splash-spacer'>
                    <img src='/images/logo-source.svg' alt='Olasio Source' />
                </div>
                {this.props.children}
            </div>
        );
    }
}

interface LayoutProps {

}
