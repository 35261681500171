export function requestCameraPermission() {
    return new Promise<boolean>((resolve, reject) => {
        let localstream: MediaStream | undefined;
        if (navigator.mediaDevices.getUserMedia !== null) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(response => {
                    if (response) {
                        resolve(response.active)
                    }
                    else {
                        resolve(false)
                    }
                    localstream = response;
                })
                .then(() => {
                    localstream?.getTracks().forEach(track => {
                        track.stop();
                    });
                })
                .catch((err) => {
                    console.log("Camera permission denied: ", err)
                    resolve(false)
                });
        }
    })
}
