export enum ItemType {
    unknown = 'Unknown',//0
    identifier = 'Identifier', //1
    inspection = 'Inspection', //2
    text = 'Text', //3
    photo = 'Photo', //4
    dropDown = 'DropDown', //5
    label = 'Label', //6
    signature = 'Signature', //7
    date = 'Date', //8
    rating = 'Rating', //9
    compliancePlate = 'CompliancePlate', //10
    buildPlate = 'BuildPlate', //11
    registrationNumber = 'RegistrationNumber', //12
    odometer = 'Odometer', //13
    serialNumber = 'SerialNumber', //14
    vehicle = 'Vehicle', //15
    overview = 'Overview', //16
    genericAsset = 'GenericAsset', //17
    sectionRating = 'SectionRating', //18
    carfacts = 'Carfacts', //19
    stockLookup = 'StockLookup', //21
    group = 'Group', //22
    gps = "Gps", //23
    brakeTest = 'BrakeTest', //24
    roadworthy = 'RoadWorthy', //25
    score = 'Score', //26
    dateTime = 'DateTime', //27
    time = 'Time', //28
    number = 'Number', //29
    tally = 'Tally', //30
    yesNo = 'YesNo', //31
    api = 'Api', //32
    data = 'Data', //33
    button = 'Button', //34
    photoRoll = 'PhotoRoll', //35
    media = 'Media', //36
    verifyIdentity = 'VerifyIdentity', //37
    verifyVehicle = 'VerifyVehicle', //38
    verifyCompliancePlate = 'VerifyCompliancePlate', //39
    verifyBuildPlate = 'VerifyBuildPlate', //40
    verifyBankAccount = 'VerifyBankAccount', //41
    verifyOdometer = 'VerifyOdometer', //42
    remoteInspection = 'RemoteInspection', //43
    location = 'Location', //44
    livePhoto = 'LivePhoto', //45
    verifyNccIdCheck = 'VerifyNccIdCheck' //46
}
