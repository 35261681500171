import { Condition } from './condition';
import { Hint } from './hint';
import { ItemType } from '../itemTypes';

type BaseItem = {
    id: number;
    name: string;
    display: string;
    iconGuid: string;
    // itemType: ItemType;
    required: boolean;
    requiresVisit: boolean;
    ordinal: number;
    keepWhenCopied: boolean;
    conditions: Condition[];
    hints: Hint[];
    autoSubmit: boolean;
    hasExternalDependency: boolean;
    allowsFromLibrary: boolean;
    allowableTypes: MediaType[];
    facingMode: string;
};

export type VerifyVehicleItem = BaseItem & {
    itemType: ItemType.verifyVehicle;
    photoNames: string[];
    uploadImageIndex: number;
};

export type VerifyOdometerItem = BaseItem & {
    itemType: ItemType.verifyOdometer;
};

export type VerifyCompliancePlateItem = BaseItem & {
    itemType: ItemType.verifyCompliancePlate;
    vehicleType: string;
    hideDate: boolean;
};

export type VerifyBuildPlateItem = BaseItem & {
    itemType: ItemType.verifyBuildPlate;
    vehicleType: string;
};

export type VerifyIdentityItem = BaseItem & {
    itemType: ItemType.verifyIdentity;
};

export type VerifyBankAccountItem = BaseItem & {
    itemType: ItemType.verifyBankAccount;
};

export type PhotoItem = BaseItem & {
    itemType: ItemType.photo;
};

export type MediaItem = BaseItem & {
    itemType: ItemType.media;
};

export type GroupItem = BaseItem & {
    itemType: ItemType.group;
    items: Item[];
};

export type YesNoItem = BaseItem & {
    itemType: ItemType.yesNo;
    yesValue: string;
    noValue: string;
    nilValue: string;
    photoDisplay: string;
}

export type TextItem = BaseItem & {
    itemType: ItemType.text;
    maxLength: number;
    // none of the following are currently being used... i like it
    validationRegEx: string;
    validationMessage: string;
    keyboard: string;
    options: string[];
}

export type DropDownItem = BaseItem & {
    itemType: ItemType.dropDown;
    multiSelect: boolean;
    options: string[];
}

export type DateItem = BaseItem & {
    itemType: ItemType.date;
    granularity: DateGranularity;
    defaultToNow: boolean;
}

export enum DateGranularity {
    Date = 'D', //0
    Month = 'M', //1
    Year = 'Y', //2
}

export type LocationItem = BaseItem & {
    itemType: ItemType.location;
}

export type SignatureItem = BaseItem & {
    itemType: ItemType.signature;
}

export type GpsItem = BaseItem & {
    itemType: ItemType.gps;
}

export type LivePhotoItem = BaseItem & {
    itemType: ItemType.livePhoto;
    duration: number;
    message: string;
}

export type VerifyNccIdCheckItem = BaseItem & {
    itemType: ItemType.verifyNccIdCheck;
}

export enum MediaType {
    Image = 'Image', //0
    Video = 'Video', //1
    LivePhoto = 'LivePhoto', //2
    PDF = 'PDF' //3
}

export type Item =
    | VerifyVehicleItem
    | VerifyOdometerItem
    | VerifyCompliancePlateItem
    | VerifyBuildPlateItem
    | VerifyIdentityItem
    | VerifyBankAccountItem
    | PhotoItem
    | GroupItem
    | YesNoItem
    | DateItem
    | DropDownItem
    | TextItem
    | LocationItem
    | SignatureItem
    | GpsItem
    | LivePhotoItem
    | VerifyNccIdCheckItem
    | MediaItem;
