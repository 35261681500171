import * as React from 'react';
import cn from 'classnames';
import styles from './toggle.module.scss';

export const Toggle: React.FC<Props> = (props) => {
    return <div className={styles.toggle}>
        {props.values.map(v => <Option key={v} onClick={() => props.onChange(v)} active={props.value === v} val={v} readOnly={props.readOnly} />)}
    </div>
}

const Option: React.FC<OptionProps> = ({ val, active, readOnly, onClick }) => {
    return <div className={cn(styles.option, { [styles.active]: active }, {[styles.disabled]: readOnly })} onClick={onClick}>
        {val}
    </div>
}

interface Props {
    values: string[];
    value?: string;
    onChange: (val: string) => void;
    readOnly?: boolean;
}

interface OptionProps {
    val: string;
    active?: boolean;
    onClick: () => void;
    readOnly?: boolean;
}
