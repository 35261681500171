import React from 'react';
import Slider from 'react-slick';
import styles from './imageViewer.module.scss';
import { ReactComponent as CameraPlaceholder } from '../icons/camera-placeholder.svg';
import { useImages } from '../../store/images/hooks';
import { Image } from '../../models/submissions/image';

type Props = {
    images: Image[];
    onClick: () => void;
};

export const ImageViewer: React.FC<Props> = props => {
    const images = useImages(props.images);

    return (
        <div className={styles.normal}>
            {images.length > 0 ? (
                <Slider arrows={false} dots>
                    {images.map(image => (
                        <div key={image.guid} className={styles.slide} >
                            <img src={image.url} />
                        </div>
                    ))}
                </Slider>
            ) : (
                <div className={styles.placeholder}>
                    <div role="button" onClick={props.onClick}>
                        <CameraPlaceholder />
                        <span>Tap to add photo</span>
                    </div>
                </div>
            )}
        </div>
    );
};
