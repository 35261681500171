import React from 'react';
import { Select } from '../core/select';
import { DropDownItem } from '../../models/schema/item';
import { DropDownItemResponse } from '../../models/submissions/itemResponse';
import { ItemType } from '../../models/itemTypes';

type Props = {
  item: DropDownItem;
  response?: DropDownItemResponse;
  onChange: (response: DropDownItemResponse) => void;
  readOnly?: boolean;
};

// TODO: handle multi-select

export const GroupDropDownControl = (props: Props) => {
  return (
    <Select
      label={props.item.display}
      value={props.response ? props.response.values[0] : ''}
      onChange={e => {
        props.onChange({
          Type: 'Inspections.DropDownItemResponse',
          itemType: ItemType.dropDown,
          values: [e.target.value],
        });
      }}
      disabled = {props.readOnly}
    >
      <option value="" disabled />
      {props.item.options.map(option => (
        <option key={option}>{option}</option>
      ))}
    </Select>
  );
};
