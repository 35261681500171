import React from 'react';
import styles from './input.module.scss';

type Props = React.SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string;
};

export const Select = (props: Props) => {
  const { label, ...attr } = props;

  return (
    <div className={styles.container}>
      {label && <label>{label}</label>}
      <select {...attr}>{props.children}</select>
    </div>
  );
};
