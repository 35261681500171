import React, { useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import styles from './imageUpload.module.scss';
import { uploadImage, uploadLivePhotoImages, uploadPhotoImage } from '../../store/images/imagesSlice';
import { useImages } from '../../store/images/hooks';
import { Spinner } from './spinner';
import { Image } from '../../models/submissions/image';
import addIcon from '../icons/icon-add-photo.svg';
import binIcon from '../icons/icon-trash-sm.svg';
import { Camera } from './camera';

type Props = {
  images: Image[];
  onChange: (images: Image[]) => void;
  onDelete: (guid: string) => void;
  label?: string;
  multi?: boolean;
  itemId: number;
  onAddRaw?: (files: File[]) => void; // when the original data is needed
  readOnly?: boolean;
  noUpload?: boolean;
  name?: string; 
  facingMode?: string;
};

type Params = {
  orderId: string;
  taskId: string;
  itemId: string;
};

export const ImageUpload = (props: Props) => {
  // TODO: these should really be props, since group items use group ID
  // how come it still works? not sure...
  const params = useParams<Params>();
  const orderId = parseInt(params.orderId);
  const taskId = parseInt(params.taskId);

  // retrieve relevant image data from store
  const images = useImages(props.images);

  const goodImages = images.filter(image => !image.error); // uploading or done
  const badImages = images.filter(image => image.error); // failed to upload

  const [takingPhoto, setTakingPhoto] = useState(false);

  const dispatch = useDispatch();

  const [rejected, setRejected] = useState(false);
  const handleImageUpload = (acceptedFiles: File[], rejectedFiles: File[]) => {
    if (rejectedFiles.length > 0) {
      setRejected(true);
      return;
    } else {
      setRejected(false);
    }

    const newImages: Image[] = [];

    acceptedFiles.forEach(file => {
      const image = {
        Type: 'Image' as const,
        imageGuid: uuid(),
        createdUtc: new Date().toISOString(),
        data: null,
      };

      newImages.push(image);

      dispatch(uploadImage(orderId, taskId, props.itemId, image, file));
    });

    // map good images back to their `Image` object
    const uploadedImages = goodImages.map(imageState => {
      return props.images.find(image => image.imageGuid === imageState.guid)!;
    });
    props.onChange([...uploadedImages, ...newImages]);

    if (props.onAddRaw) props.onAddRaw(acceptedFiles);
  };

  const handleNewCameraImage = (newImage: Image) => {
    try {
      setRejected(false);
      dispatch(uploadPhotoImage(orderId, taskId, props.itemId, newImage));

      const uploadedImages = goodImages.map(imageState => {
        return props.images.find(image => image.imageGuid === imageState.guid)!;
      });
      props.onChange([...uploadedImages, newImage]);
      setTakingPhoto(false);
    }
    catch (err) {
      console.log(err);
      setTakingPhoto(false);
    }
  }

  const handleImageDelete = (guid: string) => {
    // NOTE: Deleting images from store will cause issues when responses aren't
    // saved (it will try reference a non-existing image). Question is do we
    // really need to delete from the store at all?
    // dispatch(deleteImage(guid));
    props.onDelete(guid);
  };

  const showCamera = () => {
    setTakingPhoto(true);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleImageUpload,
    accept: ['image/jpeg', 'image/png'],
    multiple: !!props.multi,
    disabled: props.readOnly || props.noUpload
  });

  const showAddTile = goodImages.length === 0 || props.multi;
  const showError = badImages.length > 0 || rejected;
  const facingMode = props.facingMode == 'User' ? 'user' : 'environment';

  return takingPhoto ? (<Camera
    onCancel={() => {
      setTakingPhoto(false);
    }}
    onPhotoTaken={handleNewCameraImage}
    title={`Take Photo ${props.name ? `(${props.name})` : ''}`}
    defaultFacingMode= {facingMode}
  />) :
  (
    <>
      {props.name && (
        <div className={styles.name}>{props.name}</div>
      )}
      {props.label && (
        <label className={styles.instruction}>{props.label}</label>
      )}
      <div className={styles.container}>
        {goodImages.map(image => {
          if (image.isUploading) {
            return (
              <div key={image.guid} className={cn(styles.tile, styles.add)}>
                <Spinner />
              </div>
            );
          }
          return (
            <div key={image.guid} className={cn(styles.tile, styles.image)}>
              <img src={image.url} alt="User-uploaded" />
              <button
                className={styles.delete}
                onClick={() => handleImageDelete(image.guid)}
              >
                {!props.readOnly ? <img src={binIcon} alt="Delete" /> : null}
              </button>
            </div>
          );
        })}
        {showAddTile && (
          !props.noUpload ? <div
            {...getRootProps()}
            className={cn(styles.tile, styles.add, styles.pointer, {[styles.disabled]: props.readOnly })}
          >
            <input {...getInputProps()} />
            <img src={addIcon} alt="" />
            <p>{!props.readOnly ? "Add photo" : "No photo"}</p>
            <small>* Only JPEG and PNG images will be accepted.</small>
          </div> :
          <div
            onClick={showCamera}
            className={cn(styles.tile, styles.add, styles.pointer, {[styles.disabled]: props.readOnly })}
          >
            <img src={addIcon} alt="" />
            <p>{!props.readOnly ? "Take photo" : "No photo"}</p>
            <small>* Must use on-device camera.</small>
          </div>
        )}
      </div>
      {showError && (
        <p className={styles.error}>
          {rejected
            ? 'One or more image is invalid, please verify and try again.'
            : props.multi
              ? 'One or more images failed to upload, please try again.'
              : 'Image failed to upload, please try again.'}
        </p>
      )}
    </>
  );
};
