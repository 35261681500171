import { Fragment, useEffect, useState } from 'react';
import { ItemType } from '../../models/itemTypes';
import { VerifyNccIdCheckItem } from '../../models/schema/item';
import { VerifyNccIdCheckResponse } from '../../models/submissions/itemResponse';
import { _apiUrl } from '../../settings';
import { appFetch } from '../../tools/fetch';
import { Button } from '../core/button';
import { Input } from '../core/input';
import { Loading } from '../core/loading';
import { Page, PageMain } from '../core/page';
import { Toggle } from '../core/toggle';
import styles from './verifyNccControl.module.scss';

export const VerifyNccControl: React.FC<Props> = (props) => {
    const [firstName, setFirstName] = useState(props.response?.firstName || '');
    const [middleName, setMiddleName] = useState(props.response?.middleName || '');
    const [lastName, setLastName] = useState(props.response?.lastName || '');
    const [dateOfBirth, setDateOfBirth] = useState(props.response?.dateOfBirth || '');
    const [nameChanged, setNameChanged] = useState(props.response?.nameChanged || false);

    const onContinue = () => {
        // Continue to external
        if (props.readOnly && props.response?.customerUrl) {
            setLoading(true);
            document.location.href = props.response?.customerUrl;
        }
        else if (props.response?.customerUrl) {
            setLoading(true);
            props.onConfirm(props.response, false, () => {
                if (props.response?.customerUrl)
                    document.location.href = props.response?.customerUrl;
            });
        }
        else {
            createCheck();
        }
    }

    useEffect(() => {
        if (props.response?.firstName)
            setFirstName(props.response?.firstName || '');
        if (props.response?.middleName)
            setMiddleName(props.response?.middleName || '');
        if (props.response?.lastName)
            setLastName(props.response?.lastName || '');
        if (props.response?.dateOfBirth)
            setDateOfBirth(props.response?.dateOfBirth || '');
        if (props.response?.nameChanged)
            setNameChanged(props.response?.nameChanged || false);
    }, [props.response]);

    const createCheck = () => {
        const url = `${_apiUrl}/identity/createcheck/${props.hash}/${props.taskId}/${props.itemId}`;
        const createCheckData = {
            firstName,
            middleName,
            lastName,
            dateOfBirth,
            nameChanged
        };

        setLoading(true);

        appFetch(url, 'post', JSON.stringify(createCheckData))
            .then(response => {
                if (response.ok)
                    return response.json();
                else
                    return response.text().then(text => { throw text });
            })
            .then((checkResponse: { continueUrl: string }) => {
                props.onConfirm({
                    Type: 'Inspections.VerifyNccIdCheckResponse',
                    itemType: ItemType.verifyNccIdCheck,
                    dateOfBirth: dateOfBirth,
                    firstName: firstName,
                    lastName: lastName,
                    middleName: middleName,
                    nameChanged: nameChanged,
                    customerUrl: checkResponse.continueUrl
                }, true);

                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const [loading, setLoading] = useState(false);

    return <Page onBack={props.onExit} title='Advanced ID Check'>
        {!props.readOnly ? 
        !loading && !props.response?.customerUrl && <p className={styles.instruction}>
            If you don't have a middle name, leave that field blank. If you have only a single name, please use 
            the "Last Name" field.
        </p> : null}
        {loading ? <Loading /> : props.response?.customerUrl || props.readOnly ?
            <PageMain>
                <h3>
                    {props.readOnly ? "Resume with NCC" : "Continue to NCC"}
                </h3>
                <p>
                    Your identity will be checked by our service provider National Crime Check Pty Ltd (NCC). 
                    Information you supply will be handled per NCC’s&nbsp;
                    <a href='https://www.nationalcrimecheck.com.au/privacy_policy'>Privacy Policy</a>.
                </p>
                <p>
                    By continuing, you consent to Olasio sharing your personal information with NCC to enable NCC to 
                    perform the ID verification.  
                </p>
                <p>
                    You’ll be asked to provide photos of yourself and your ID.
                </p>
            </PageMain> :
            <PageMain>
                <Input
                    label='First Name'
                    defaultValue={firstName}
                    onChange={(e) => setFirstName(e.target.value)} />
                <Input
                    label='Middle Name'
                    defaultValue={middleName}
                    onChange={(e) => setMiddleName(e.target.value)} />
                <Input
                    label='Last Name'
                    defaultValue={lastName}
                    onChange={(e) => setLastName(e.target.value)} />
                <Input
                    label='Date of Birth'
                    defaultValue={dateOfBirth}
                    type='date'
                    onChange={(e) => setDateOfBirth(e.target.value)} />
                <div className={styles.label}>
                    <label>Has your name changed?</label>
                    <Toggle
                        value={nameChanged ? 'Yes' : 'No'}
                        values={['Yes', 'No']}
                        onChange={(val) => setNameChanged(val === 'Yes')}
                        />
                </div>
            </PageMain>}
        <Button
            color='green'
            onClick={onContinue}
            disabled={!lastName || !dateOfBirth || loading}>
            {props.readOnly ? 'Resume' : props.response?.customerUrl ? 'Continue to NCC' : 'Continue'}
        </Button>
    </Page>;
}

interface Props {
    inGroup?: boolean;
    item: VerifyNccIdCheckItem;
    onExit: () => void;
    onConfirm: (data: VerifyNccIdCheckResponse, stayIn?: boolean, callback?: () => void) => void;
    response?: VerifyNccIdCheckResponse;

    taskId: string;
    itemId: string;
    orderId: string;
    hash: string;
    readOnly?: boolean;
}
