import React, { useState } from 'react';
import styles from './sharedControl.module.scss';
import { InfoStep } from './infoStep';
import { PlateGuide } from '../core/plateGuide';
import { VerifyBuildPlateItem } from '../../models/schema/item';
import { VerifyBuildPlateItemResponse } from '../../models/submissions/itemResponse';
import { MonthOptions, range } from '../../tools/selectOptions';
import { ImageViewer } from '../core/imageViewer';
import { Select } from '../core/select';
import { ImageUpload } from '../core/imageUpload';
import { useImageStatus } from '../../store/images/hooks';
import { Page, PageMain } from '../core/page';
import { Button } from '../core/button';

type Props = {
    item: VerifyBuildPlateItem;
    response?: VerifyBuildPlateItemResponse;
    onExit: () => void;
    onConfirm: (data: VerifyBuildPlateItemResponse) => void;
    readOnly?: boolean;
};

enum Steps {
    Info,
    PlateGuide,
    Photo,
    Summary,
    MissingInfo,
}

const currentYear = new Date().getFullYear();
const years = range(currentYear, currentYear - 99);

export const VerifyBuildPlateControl: React.FC<Props> = ({ item, response, onExit, onConfirm, readOnly }) => {
    const [image, setImage] = useState(response ? response.image : undefined);

    const imageIsValid = useImageStatus(image);

    const [buildMonth, setBuildMonth] = useState(response && response.month ? response.month.toString() : '');
    const [buildYear, setBuildYear] = useState(response && response.year ? response.year.toString() : '');

    // steps
    const [step, setCurrentStep] = useState(response ? Steps.Summary : Steps.Info);
    const [previousStep, setPreviousStep] = useState<Steps | null>(null);
    const setStep = (s: Steps) => {
        if (s === previousStep) {
            setPreviousStep(0);
        }
        else {
            setPreviousStep(step);
        }

        setCurrentStep(s);
    }

    const isCar = item.vehicleType === 'car' || !item.vehicleType;

    const handleBack = () => {
        if (step === Steps.Info || response) {
            onExit();
        } else {
            setStep(previousStep == null ? (step - 1) : previousStep);
        }
    };

    const getStep = () => {
        switch (step) {
            case Steps.Info:
                return (
                    <>
                        <PageMain center>
                            <InfoStep
                                image="/images/build-verify.svg"
                                text={isCar
                                    ? "Take a photo of the build plate on your vehicle. Or follow the guide to locate the build plate."
                                    : "Take a photo of the build plate on your vehicle."
                                }
                            >
                                <p style={{ color: '#db0000'}}>Please take extra precaution when engine is hot.</p>
                            </InfoStep>
                        </PageMain>
                        <Button color="blue" onClick={() => setStep(Steps.Photo)}>Start</Button>
                        {isCar && (
                            <Button color="outline" marginTop="15px" onClick={() => setStep(Steps.PlateGuide)}>Locate my build plate</Button>
                        )}
                    </>
                );
            case Steps.PlateGuide:
                return (
                    <PlateGuide
                        onStart={() => setStep(Steps.Photo)}
                        onCannotFind={() => setStep(Steps.Summary)}
                        cannotFindText="Cannot find my build plate"
                    />
                );
            case Steps.Photo:
                return (
                    <>
                    <PageMain>
                        <ImageUpload
                            label="Photo of build plate"
                            images={image ? [image] : []}
                            onChange={images => setImage(images[0])}
                            onDelete={() => setImage(undefined)}
                            itemId={item.id}
                            readOnly={readOnly}
                        />
                    </PageMain>
                    <Button color="green" onClick={() => setStep(Steps.Summary)} disabled={!imageIsValid}>
                        Next
                    </Button>
                    </>
                );
            case Steps.Summary:
                return (
                    <>
                    <PageMain>
                        <ImageViewer
                            images={image ? [image] : []}
                            onClick={() => setStep(Steps.Photo)}
                        />
                        <div className={styles.doubleInputs}>
                            <Select
                                label="Build date"
                                required
                                value={buildMonth}
                                onChange={e => setBuildMonth(e.target.value)}
                                disabled={readOnly}
                            >
                                <option value="" disabled>Month</option>
                                {MonthOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.name}
                                    </option>
                                ))}
                            </Select>
                            <Select
                                required
                                value={buildYear}
                                onChange={e => setBuildYear(e.target.value)}
                                disabled={readOnly}
                            >
                                <option value="" disabled>Year</option>
                                {years.map(year => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </PageMain>
                    <Button color="green" onClick={checkResponse} disabled={readOnly}>Confirm</Button>
                    </>
                );
            case Steps.MissingInfo:
                return (
                    <>
                        <PageMain center>
                            <InfoStep
                                image="/images/icon-missinginfo.svg"
                                text="We noticed some information is missing, are you sure you want to proceed."
                            />
                        </PageMain>
                        <Button color="blue" onClick={handleSubmit}>Confirm</Button>
                        <Button color="outline" marginTop="15px" onClick={() => setStep(Steps.Summary)}>Back</Button>
                    </>
                );
            default:
                return <div>Oops. Something went wrong. Try reloading the app.</div>;
        }
    };

    const checkResponse = () => {
        if (!image || !buildMonth || !buildYear) {
            setStep(Steps.MissingInfo);
            return;
        }
        handleSubmit();
    };

    const handleSubmit = () => {
        onConfirm({
            itemType: item.itemType,
            Type: 'Inspections.VerifyBuildPlateItemResponse',
            image: image,
            month: parseInt(buildMonth),
            year: parseInt(buildYear),
            hasPlate: !!image,
        });
    };

    return (
        <Page title={item.display} onBack={handleBack}>
            {getStep()}
        </Page>
    );
};
