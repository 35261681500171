import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from './login.module.scss';
import { Loading } from './core/loading';
import { _apiUrl } from '../settings';
import { appFetch } from '../tools/fetch';
import { camelize } from '../tools/objectTransform';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from './core/button';

export const LoginAuto = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [orderError, setOrderError] = useState('');
  const [email, setEmail] = useState('');
  const [taskCode, setTaskCode] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [resume, setResume] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const { hash } = useParams<{ hash: string }>();

  const login = async () => {
    setLoading(true);

    try {
      const res = await appFetch(
        `${_apiUrl}/jobs`,
        'post',
        JSON.stringify({ Hash: taskCode, Email: email })
      );

      if (!res.ok) {
        throw new Error(`${res.status}`);
      }

      const data = await res.json();
      dispatch({
        type: 'APPLICATION_LOGIN_SUCCESS',
        order: camelize(data),
      });
      history.push(`/order/${data.orderId}`);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setError('Something went wrong, please try again.');
    }
  };

  useEffect(() => {
    if (hash) {
      appFetch(`${_apiUrl}/jobs/prefill/${hash}`)
        .then(prefillRes => {
          if (!prefillRes.ok) {
            let err = '';
            switch (prefillRes.status) {
              case 403:
              case 412:
                err = 'Looks like this link is no longer valid. This task may have been cancelled, already completed or altered by the sender.';
                break;
              case 404:
                err = 'Looks like this task link is not valid. If you believe this is in error, please contact whoever sent you this task on how to proceed.';
                break;
              case 400:
              default:
                err = 'Looks like something went wrong here. If you believe this is in error, please contact whoever sent you this task on how to proceed.';
                break;
            }
            let e =  new Error(err);
            e.name = 'CustomOrderError';
            throw e;
          }

          return prefillRes.json();
        })
        .then(result => {
          const text = result.email;
          setEmail(text.replace(/"/gi, ''));
          setTaskCode(hash.slice(0, 8));
          setReadOnly(result.readOnly);
          setResume(result.resume);
          setLoading(false);
        })
        .catch((err: Error) => {
          if (err.name === 'CustomOrderError') {
            setOrderError(`${err.message}`);
          }
          else {
            setOrderError('Please try again later.');
            setError('Something went wrong.');
          }

          setLoading(false);
        })
    }
  }, [hash]);

  return loading ? (
    <Loading text="" />
  ) : orderError ? <div className="splash-content">
      <div className={styles.spacing} style={{ textAlign: 'center' }}>
        <h3>{error ? error : 'Task unavailable'}</h3>
        <p>{orderError}</p>
          <Button color="green" marginTop="30px" onClick={() => {
            history.push('/');
            dispatch({
                type: 'APPLICATION_LOGOUT', 
            });
          }}>
            Home
          </Button>
          <Button color="outline" marginTop="15px" onClick={() => {
            setLoading(true);
            document.location.reload();
          }}>
            Retry
          </Button>
      </div>
    </div> : (
    <div className="splash-content">
      {error && (
        <div className={styles.error}>
          <p>{error}</p>
          <button type="button" onClick={() => setError('')}>
            &times;
          </button>
        </div>
      )}
      <div className={styles.spacing}>
        <label className={styles.tnc}>
          I agree to the <a href="https://www.olasio.com/source/termsandconditions" target="_blank">End User 
          Terms</a> and <a href="https://www.olasio.com/source/privacypolicy" target="_blank">Privacy Policy</a>
          {/* the default checkbox below is hidden and we implement a custom one on <span /> */}
          <input
            type="checkbox"
            checked={termsChecked}
            onChange={() => setTermsChecked(!termsChecked)}
          />
          <span />
        </label>
        <hr />
        <p className={styles.line2}>Please view the <a href="https://www.olasio.com/source/privacypolicy" target="_blank">Olasio Personal Information Collection Statement</a></p>
        <Button color="green" marginTop="30px" onClick={login} disabled={!termsChecked}>
          {readOnly ? resume ? "Resume" : "Review" : "Confirm"}
        </Button>
      </div>
    </div>
  );
};
