import React from 'react';
import styles from './input.module.scss';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
};

export const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, ...attr } = props;

  return (
    <div className={styles.container}>
      {label && <label>{label}</label>}
      <input {...attr} ref={ref} />
    </div>
  );
});
