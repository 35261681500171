import * as React from 'react';
import './camera.css';
import { v4 as uuid } from 'uuid';
import { getCurrentPosition } from '../../tools/geolocation';
import { GpsData } from '../../models/submissions/gpsData';
import { Image as ImageType } from '../../models/submissions/image';
import cameraTick from '../icons/tick-camera.svg';
import cameraRetake from '../icons/back-camera.svg';
import { Fullscreen } from './fullscreen';
import { Page } from './page';
import { DevicePermissions } from '../devicePermissions';

export class Camera extends React.Component<CameraProps, CameraState> {
    render() {
        return (
            <Fullscreen>
                {!this.state.startFailed ? <div className='camera'>
                    <button className='cancel' onClick={this.cancel}>&times;</button>
                    <header>
                        {this.props.title || 'Take photo'}
                    </header>
                    <div className='body'>
                        <video className='camera-viewport' autoPlay playsInline ref={this.videoRef} style={{ display: this.state.showingPreview ? 'none' : 'block' }}></video>
                        <img className='camera-output' ref={this.imageRef} style={{ display: this.state.showingPreview ? 'block' : 'none' }} />
                    </div>
                    <footer>
                        {this.state.showingPreview ?
                            <div className='post-options'>
                                <button className='option' onClick={this.retry}>
                                    <img src={cameraRetake} />
                                </button>
                                <button className='option' onClick={this.confirm}>
                                    <img src={cameraTick} />
                                </button>
                            </div> :
                            <div className="post-options">
                                <button
                                    className='option flash-button'
                                    onClick={this.toggleFlash}
                                    disabled={!this.state.cameraStarted || this.state.showingPreview}
                                    style={!this.state.cameraStarted ? { opacity: 0.25 } : undefined}>
                                    <img src={`/images/icon-flash-${this.state.flashStatus}.svg`} />
                                </button>
                                <button
                                    className='snap'
                                    onClick={this.snap}
                                    disabled={!this.state.cameraStarted || this.state.showingPreview}
                                    style={!this.state.cameraStarted ? { opacity: 0.25 } : undefined}>
                                </button>
                                <button className='option option-switch' onClick={this.switch}>
                                    <img src={'/images/icon-rotate.svg'} alt="Switch" />
                                </button>
                            </div>
                        }
                    </footer>
                </div> :
                <Page onBack={() => {
                    this.props.onCancel();
                }}>
                    <DevicePermissions cameraRequested={true} locationRequested={false} locationAccuracyRequested={false} />
                </Page>}
            </Fullscreen>
        );
    }

    constructor(props: CameraProps) {
        super(props);

        this.state = {
            showingPreview: false,
            flashStatus: 'auto',
            cameraStarted: false,
            loadingPreview: false,
            startFailed: false,
            facingMode: props.defaultFacingMode
        };

        // Refs
        this.videoRef = React.createRef();
        //this.canvasRef = React.createRef();
        this.imageRef = React.createRef();

        // Bindings
        this.snap = this.snap.bind(this);
        this.confirm = this.confirm.bind(this);
        this.retry = this.retry.bind(this);
        this.cancel = this.cancel.bind(this);
        this.switch = this.switch.bind(this);
        this.toggleFlash = this.toggleFlash.bind(this);
    }

    // Refs
    videoRef: React.RefObject<HTMLVideoElement>;
    //canvasRef: React.RefObject<HTMLCanvasElement>;
    imageRef: React.RefObject<HTMLImageElement>;

    // Privates
    stream?: MediaStream;

    componentDidMount() {
        this.start();
        this.getCoords();
    }

    componentWillReceiveProps(props: CameraProps) {
        if (props.photoIndex != undefined && props.photoIndex != this.props.photoIndex)
            this.retry();
    }

    start() {
        this.stop();

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: { facingMode: this.state.facingMode } })
            .then((stream) => {
                this.stream = stream;
                if (this.videoRef.current) {
                    this.videoRef.current.srcObject = stream;
                    this.videoRef.current.play();
                    this.videoRef.current.style.opacity = '0';
                    this.videoRef.current.onloadedmetadata = () => {
                        if (this.videoRef.current) {
                            this.videoRef.current.play();
                            this.videoRef.current.style.opacity = '1';
                        }
                    }
                    this.setState({ cameraStarted: true })
                }
            })
            .catch(err => {
                console.log(err, err.message, err.name);
                this.setState({ startFailed: true });
            });
        }
        else {
            this.setState({ startFailed: true });
        }
    }

    async getCoords() {
        try {
            const pos = await getCurrentPosition();
            const { longitude, latitude, accuracy } = pos.coords;
            this.setState({ coords: { longitude, latitude, accuracy } });
        } catch (e) {
            // TODO
        }
    }

    snap() {
        if (this.videoRef.current) {
            if (typeof ImageCapture !== typeof undefined) {
                if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                    navigator.mediaDevices.getUserMedia({ video: { facingMode: this.state.facingMode } })
                    .then((stream) => {
                        const track = stream.getVideoTracks()[0];
                        const capture = new ImageCapture(track);
                        this.stop();
                        capture.takePhoto().then(blob => {
                            this.setState({
                                loadingPreview: true
                            });
                            let reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onloadend = () => {
                                // rotate image, as ImageCapture seems to always take photo in landscape
                                this.stop(stream);
                                const img = new Image();
                                img.src = `${reader.result}`;
                                img.onload = () => {
                                    const canvas = document.createElement('canvas');
                                    const imageSize = 2000;
                                    
                                    let width = img.width, height = img.height;
                                    if (width > imageSize) {
                                        let scaleDownFactor = Math.floor(width / imageSize);

                                        width = Math.round(width / scaleDownFactor);
                                        height = Math.round(height / scaleDownFactor);
                                    }

                                    canvas.width = width;
                                    canvas.height = height;
                                    const ctx = canvas.getContext('2d')!;
                                    // I do not remember why this was here, but I'm going to try removing it
                                    /*ctx.translate(img.height, img.width / img.height);
                                    ctx.rotate(Math.PI / 2);*/
                                    ctx.drawImage(img, 0, 0);//, img.width, img.height, 0, 0, width, height);
                                    const rotatedImg = canvas.toDataURL('image/jpeg', 0.7);

                                    if (this.imageRef.current) {
                                        this.imageRef.current.src = rotatedImg;
                                    }
                                    this.setState({
                                        showingPreview: true,
                                        loadingPreview: false,
                                        image: rotatedImg,
                                        time: new Date().toISOString(),
                                    });
                                }
                            };
                            //const result = URL.createObjectURL(blob);
                        });
                    });
                }
            }
            else {
                const canvas = document.createElement('canvas');
                let canvasContext = canvas.getContext("2d");

                canvas.width = this.videoRef.current.videoWidth;
                canvas.height = this.videoRef.current.videoHeight;

                canvasContext!.drawImage(
                    this.videoRef.current,
                    0,
                    0,
                    this.videoRef.current.videoWidth,
                    this.videoRef.current.videoHeight);

                const imgData = canvas.toDataURL('image/jpeg', 70);
                if (this.imageRef.current) {
                    this.imageRef.current.src = imgData;
                }
                
                this.setState({
                    image: imgData,
                    time: new Date().toISOString(),
                    showingPreview: true,
                    loadingPreview: false
                });
            }

            if (this.videoRef.current.srcObject) {
                (this.videoRef.current.srcObject as MediaStream).getTracks().forEach(track => track.stop());
            }
        }
    }

    stop(stream?: MediaStream) {
        if (!stream)
            stream = this.stream;
        if (stream) {
            stream.getTracks().forEach(function(track) {
                if (track.readyState == 'live' && track.kind === 'video') {
                    track.stop();
                }
            });
        }
    }

    confirm() {
        if (this.state.image && this.state.coords && this.state.time) {
            this.props.onPhotoTaken({
                Type: 'Image',
                imageGuid: uuid(),
                createdUtc: this.state.time,
                data: this.state.image,
                gpsData: this.state.coords, // calculated when component mounts
                comment: this.props.title,
            });
        }
    }

    retry() {
        this.start();
        this.setState({ showingPreview: false });

        if (this.imageRef.current) {
            this.imageRef.current.src = '';
        }
    }

    cancel() {
        this.props.onCancel();
    }

    switch() {
        this.setState({ 
            facingMode: this.state.facingMode == 'environment' ? 'user' : 'environment'
        }, () => {
            this.start();
        });
    }

    toggleFlash() {
        let next: FlashStatus;
        switch (this.state.flashStatus) {
            case 'auto':
                next = 'flash';
                break;
            case 'flash':
                next = 'off';
                break;
            case 'off':
                next = 'auto';
                break;
            default:
                next = 'auto';
                break;
        }
        this.setState({ flashStatus: next });
    }

    componentWillUnmount() {
        if (this.videoRef.current && this.videoRef.current.srcObject) {
            (this.videoRef.current.srcObject as MediaStream).getTracks().forEach(track => track.stop());
        }
    }
}

interface CameraProps {
    onPhotoTaken: (image: ImageType) => void;
    onCancel: () => void;
    title?: string;
    photoIndex?: number;
    defaultFacingMode: 'environment' | 'user';
}

interface CameraState {
    showingPreview: boolean;
    flashStatus: FlashStatus;
    cameraStarted: boolean;
    image?: string;
    coords?: GpsData;
    time?: string;
    loadingPreview: boolean;
    startFailed: boolean;
    facingMode: 'environment' | 'user';
}

type FlashStatus = 'auto' | 'flash' | 'off';
