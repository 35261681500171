import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import loadImage from 'blueimp-load-image';
import { AppThunk } from '..';
import { _apiUrl } from '../../settings';
import { Image } from '../../models/submissions/image';
import { ImageSubmission } from '../../models/submissions';

// TODO: deleteImage() should also call API and delete image from S3

type ImageState = {
  [guid: string]: {
    isUploading: boolean;
    url: string;
    error: string;
  };
};

type UploadImageSuccess = {
  guid: string;
  url: string;
};

type UploadImageFailure = {
  guid: string;
  error: string;
};

const initialState: ImageState = {};

const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    uploadImageStart(state, action: PayloadAction<string>) {
      const guid = action.payload;
      state[guid] = {
        isUploading: true,
        url: '',
        error: '',
      };
    },
    uploadImageSuccess(state, action: PayloadAction<UploadImageSuccess>) {
      const { guid, url } = action.payload;
      state[guid].isUploading = false;
      state[guid].url = url;
    },
    uploadImageFailure(state, action: PayloadAction<UploadImageFailure>) {
      const { guid, error } = action.payload;
      state[guid].isUploading = false;
      state[guid].error = error;
    },
    deleteImage(state, action: PayloadAction<string>) {
      const guid = action.payload;
      delete state[guid];
    },
  },
});

const {
  uploadImageStart,
  uploadImageSuccess,
  uploadImageFailure,
} = imagesSlice.actions;

export const { deleteImage } = imagesSlice.actions;

export default imagesSlice.reducer;

export const uploadImage = (
  orderId: number,
  taskId: number,
  itemId: number,
  image: Image,
  file: File
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(uploadImageStart(image.imageGuid));

    const { appState } = getState();
    const userHash = appState.orders[orderId].hash;
    const surveyGuid = appState.submissions[orderId][taskId].guid;
    const responseGuid = appState.responses[orderId][taskId][itemId].guid;

    // resize image and convert to Base64
    const canvasImage = await loadImage(file, {
      maxWidth: 2000,
      maxHeight: 2000,
      orientation: true,
      canvas: true,
    });
    const canvas = canvasImage.image as unknown as HTMLCanvasElement; // what's with this
    const base64Url = canvas.toDataURL('image/jpeg', 0.75);
    const base64Image = base64Url.split(',')[1];

    const imageSubmission = {
      guid: responseGuid,
      createdUtc: image.createdUtc,
      surveyGuid,
      itemId,
      image,
    };

    // TODO: move API call to somewhere else
    const formData = new FormData();
    formData.append('imageData', base64Image);
    formData.append('submissionJson', JSON.stringify(imageSubmission));

    const res = await fetch(`${_apiUrl}/image/${userHash}`, {
      method: 'POST',
      body: formData,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    const data = await res.json();

    dispatch(uploadImageSuccess({ guid: image.imageGuid, url: data.url }));
  } catch (e) {
    dispatch(uploadImageFailure({ guid: image.imageGuid, error: e as string }));
  }
};

export const uploadLivePhotoImages = (
  orderId: number,
  taskId: number,
  itemId: number,
  images: Image[]
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(uploadImageStart(images[0].imageGuid));

    const { appState } = getState();
    const userHash = appState.orders[orderId].hash;
    const surveyGuid = appState.submissions[orderId][taskId].guid;
    const responseGuid = appState.responses[orderId][taskId][itemId].guid;

    const imageSubmissions: ImageSubmission[] = images.map(image => ({
      guid: responseGuid,
      createdUtc: image.createdUtc,
      surveyGuid,
      itemId,
      image,
    }))

    const batchedImageSubmissions: ImageSubmission[][] = [];

    const batchSize = 10;
    for (let i = 0; i < imageSubmissions.length; i += batchSize) {
      const batch = imageSubmissions.slice(i, i + batchSize);
      batchedImageSubmissions.push(batch);
    }

    const result: any[] = [];

    for (let i = 0; i < batchedImageSubmissions.length; i++) {
      const res = await fetch(`${_apiUrl}/images/${userHash}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(batchedImageSubmissions[i]),
      });

      if (!res.ok) {
        throw new Error(res.statusText);
      }

      const data = await res.json();
      result.push(data);
    }

    const allResults = result.flat();

    dispatch(uploadImageSuccess({ guid: images[0].imageGuid, url: allResults[0].url }));
  } catch (e) {
    dispatch(uploadImageFailure({ guid: images[0].imageGuid, error: e as string }));
  }
};

export const uploadPhotoImage = (
  orderId: number,
  taskId: number,
  itemId: number,
  image: Image
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(uploadImageStart(image.imageGuid));

    const { appState } = getState();
    const userHash = appState.orders[orderId].hash;
    const surveyGuid = appState.submissions[orderId][taskId].guid;
    const responseGuid = appState.responses[orderId][taskId][itemId].guid;

    // resize image and convert to Base64
    const canvasImage = await loadImage(image.data!, {
      maxWidth: 2000,
      maxHeight: 2000,
      orientation: true,
      canvas: true,
    });
    const canvas = canvasImage.image as unknown as HTMLCanvasElement; // what's with this
    const base64Url = canvas.toDataURL('image/jpeg', 0.75);
    const base64Image = base64Url.split(',')[1];

    const imageSubmission: ImageSubmission = {
      guid: responseGuid,
      createdUtc: image.createdUtc,
      surveyGuid,
      itemId,
      image,
    };
    imageSubmission.image!.data = null;

    // TODO: move API call to somewhere else
    const formData = new FormData();
    formData.append('imageData', base64Image);
    formData.append('submissionJson', JSON.stringify(imageSubmission));

    const res = await fetch(`${_apiUrl}/image/${userHash}`, {
      method: 'POST',
      body: formData,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    const data = await res.json();

    dispatch(uploadImageSuccess({ guid: image.imageGuid, url: data.url }));
  } catch (e) {
    console.log(e);
    dispatch(uploadImageFailure({ guid: image.imageGuid, error: e as string }));
  }
};
