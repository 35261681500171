import { ApplicationStateKnownAction } from "./actions";
import { Reducer } from "redux";
import { Order } from "../../models/schema";
import { v4 as uuid } from 'uuid';
import { SurveySubmission, ResponseSubmission, SurveyScope } from '../../models/submissions';

export interface AppContainerState {
    loginSuccess: boolean;
    fetchingOrder: boolean; // used in refresh order
    orders: {
        [orderId: string]: Order;
    };
    submissions: {
        [orderId: string]: {
            [taskId: string]: SurveySubmission;
        };
    };
    responses: {
        [orderId: string]: {
            [taskId: string]: {
                [itemId: string]: ResponseSubmission;
            };
        };
    };
    submissionStatus: {
        [orderId: string]: {
            [taskId: string]: {
                isSubmitting: boolean;
                readOnly?: boolean;
                submissionError: boolean;
                submissionSuccess: boolean;
            };
        };
    };
    lastViewedPosition?: number;
}

const defaultState: AppContainerState = {
    loginSuccess: false,
    fetchingOrder: false,
    orders: {},
    submissions: {},
    responses: {},
    submissionStatus: {},
};

const storageKey = 'debug1';
export const applicationStateReducer: Reducer<AppContainerState> = (state, incomingAction): AppContainerState => {
    const action = incomingAction as ApplicationStateKnownAction;
    if (!state) {
        // This code looks janky because typescript couldn't understand the nicer code
        let json = localStorage.getItem(storageKey);

        if (json) {
            try {
                state = JSON.parse(json);
            }
            catch { }
        }

        if (!state)
            state = defaultState;
    }

    switch (action.type) {
        case 'APPLICATION_LOGIN_SUCCESS':
            const orderId = action.order.orderId;
            let s = {
                ...state,
                loginSuccess: true,
                orders: {
                    ...state.orders,
                    [orderId]: action.order,
                },
                submissions: {
                    ...state.submissions,
                    [orderId]: {},
                },
                responses: {
                    ...state.responses,
                    [orderId]: {},
                },
                submissionStatus: {
                    ...state.submissionStatus,
                    [orderId]: {},
                }
            };

            action.order.tasks.forEach((t, i) => {
                if (t.submission) {
                    s.submissions[orderId][t.taskId] = t.submission;
                    s.responses[orderId][t.taskId] = {};
                    t.submission.responses.forEach((r, j) => {
                        s.responses[orderId][t.taskId][r.itemId] = r;
                    });
                    s.submissionStatus[orderId][t.taskId] = {
                        isSubmitting: false,
                        readOnly: true,
                        submissionError: false,
                        submissionSuccess: false
                    };
                }
            });

            localStorage.setItem(storageKey, JSON.stringify(s));

            return s;
        case 'APPLICATION_START_TASK':
            // check to prevent overwrite
            if (state.submissions[action.orderId][action.taskId]) return state;
            const order = state.orders[action.orderId];
            const task = order.tasks.find(t => t.taskId === action.taskId)!;
            // generate survey (task) submission
            let startTaskState = {
                ...state,
                submissions: {
                    ...state.submissions,
                    [action.orderId]: {
                        ...state.submissions[action.orderId],
                        [action.taskId]: {
                            organisationId: order.organisationId,
                            surveyId: task.schemaId,
                            orderId: order.orderId,
                            version: '',
                            scope: SurveyScope.perUser,
                            taskId: action.taskId,
                            imageGuids: [],
                            responseGuids: [],
                            responses: [],
                            guid: uuid(),
                            createdUtc: new Date().toISOString(),
                            gpsData: action.coords,
                        },
                    },
                },
                responses: {
                    ...state.responses,
                    [action.orderId]: {
                        ...state.responses[action.orderId],
                        [action.taskId]: {},
                    },
                },
                submissionStatus: {
                    ...state.submissionStatus,
                    [action.orderId]: {
                        ...state.submissionStatus[action.orderId],
                        [action.taskId]: {
                            isSubmitting: false,
                            submissionError: false,
                            submissionSuccess: false
                        },
                    },
                },
            };

            return startTaskState;
        case 'APPLICATION_START_TASK_ITEM':
            // generate response (item) submission
            // check to prevent overwrite
            if (state.responses[action.orderId][action.taskId][action.itemId]) return state;
            return {
                ...state,
                responses: {
                    ...state.responses,
                    [action.orderId]: {
                        ...state.responses[action.orderId],
                        [action.taskId]: {
                            ...state.responses[action.orderId][action.taskId],
                            [action.itemId]: {
                                surveyGuid: state.submissions[action.orderId][action.taskId].guid,
                                itemId: action.itemId,
                                guid: uuid(),
                                createdUtc: new Date().toISOString(),
                                gpsData: action.coords,
                            },
                        },
                    },
                },
            };
        case 'APPLICATION_SAVE_TASK_ITEM':
            return {
                ...state,
                responses: {
                    ...state.responses,
                    [action.orderId]: {
                        ...state.responses[action.orderId],
                        [action.taskId]: {
                            ...state.responses[action.orderId][action.taskId],
                            [action.itemId]: {
                                ...state.responses[action.orderId][action.taskId][action.itemId],
                                response: action.itemResponse,
                            },
                        },
                    },
                },
            };
        case 'REQUEST_SUBMIT_TASK':
            return {
                ...state,
                submissionStatus: {
                    ...state.submissionStatus,
                    [action.orderId]: {
                        ...state.submissionStatus[action.orderId],
                        [action.taskId]: {
                            isSubmitting: true,
                            submissionError: false,
                            submissionSuccess: false,
                        }
                    }
                }
            };
        case 'COMPLETE_SUBMIT_TASK':
            return {
                ...state,
                submissionStatus: {
                    ...state.submissionStatus,
                    [action.orderId]: {
                        ...state.submissionStatus[action.orderId],
                        [action.taskId]: {
                            isSubmitting: false,
                            submissionError: false,
                            submissionSuccess: true,
                            readOnly: true
                        },
                    },
                },
            };
        case 'ERROR_SUBMIT_TASK':
            return {
                ...state,
                submissionStatus: {
                    ...state.submissionStatus,
                    [action.orderId]: {
                        ...state.submissionStatus[action.orderId],
                        [action.taskId]: {
                            isSubmitting: false,
                            submissionError: true,
                            submissionSuccess: false,
                        },
                    },
                },
            };
        case 'DISMISS_ERROR_SUBMIT_TASK':
            return {
                ...state,
                submissionStatus: {
                    ...state.submissionStatus,
                    [action.orderId]: {
                        ...state.submissionStatus[action.orderId],
                        [action.taskId]: {
                            isSubmitting: false,
                            submissionError: false,
                            submissionSuccess: false,
                        },
                    },
                },
            };
        case 'FETCH_ORDER_REQUEST':
            return {
                ...state,
                fetchingOrder: true,
            };
        case 'FETCH_ORDER_SUCCESS':
            return {
                ...state,
                fetchingOrder: false,
                orders: {
                    ...state.orders,
                    [action.order.orderId]: action.order,
                },
            };
        case 'FETCH_ORDER_FAILURE':
            return {
                ...state,
                fetchingOrder: false, // silent fail, might want to change that
            };
        case 'COMPLETE_TASK':
            return {
                ...state,
                submissionStatus: {
                    ...state.submissionStatus,
                    [action.orderId]: {
                        ...state.submissionStatus[action.orderId],
                        [action.taskId]: {
                            isSubmitting: false,
                            submissionError: false,
                            submissionSuccess: false,
                            readOnly: true
                        },
                    },
                },
            }
        case 'APPLICATION_LOGOUT':
            localStorage.removeItem(storageKey);
            return defaultState;
        case 'SET_LAST_VIEWED_POSITION':
            return {
                ...state,
                lastViewedPosition: action.scrollTop,
            };
        case 'CLEAR_LAST_VIEWED_POSITION':
            return {
                ...state,
                lastViewedPosition: undefined,
            }
        default:
            return state;
    }
}
