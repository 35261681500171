import React from 'react';
import styles from './plateGuide.module.scss';
import { Button } from './button';
import { ImageSlideSearcher } from '../core/imageSlideSearcher';

type Props = {
  onStart: () => void;
  onCannotFind: () => void;
  cannotFindText: string;
};

export const PlateGuide = (props: Props) => (
  <>
    <div className={styles.guide}>
      <div>
        <ImageSlideSearcher
        images={[
          { label: 'Passenger Side B-pillar', url: '/images/vehicle-locations/Passenger-Side-B-pillar.jpg', },
          { label: 'Driver Side B-pillar', url: '/images/vehicle-locations/Driver-Side-B-pillar.jpg', },
          { label: 'Driver Side Engine Bay', url: '/images/vehicle-locations/Driver-Side-Engine-Bay.jpg', },
          { label: 'Passenger Side A-pillar', url: '/images/vehicle-locations/Passenger-Side-A-pillar.jpg', },
          { label: 'Driver Side A-pillar', url: '/images/vehicle-locations/Driver-Side-A-pillar.jpg', },
          { label: 'Passenger Side Engine Bay', url: '/images/vehicle-locations/Passenger-Side-Engine-Bay.jpg', },
          { label: 'Passenger Side C-pillar', url: '/images/vehicle-locations/Passenger-Side-C-pillar.jpg', },
          { label: 'Passenger Side Boot', url: '/images/vehicle-locations/Passenger-Side-Boot.jpg', },
          { label: 'Front Panel', url: '/images/vehicle-locations/Front-Panel.jpg', },
          { label: 'Driver Side Boot', url: '/images/vehicle-locations/Driver-Side-Boot.jpg', },
          { label: 'Rear Cab Panel', url: '/images/vehicle-locations/Rear-Cab-Panel.jpg', },
          { label: 'Driver Side Front Rail', url: '/images/vehicle-locations/Driver-Side-Front-Rail.jpg', },
          { label: 'Passenger Door', url: '/images/vehicle-locations/Passenger-Door.jpg', },
          { label: 'Driver Side Floor Rear', url: '/images/vehicle-locations/Driver-Side-Floor-Rear.jpg', },
          { label: 'Driver Side C-pillar', url: '/images/vehicle-locations/Driver-Side-C-pillar.jpg', },
          { label: 'Driver Side Rear Rail', url: '/images/vehicle-locations/Driver-Side-Rear-Rail.jpg', },
        ]}
      />
      </div>
    </div>
    <div className={styles.footer}>
      <div>
        <Button color="blue" marginTop={0} onClick={props.onStart}>
          Add photo
        </Button>
        <button className={styles.cannotFind} onClick={props.onCannotFind}>
          {props.cannotFindText}
        </button>
      </div>
    </div>
  </>
);
