import * as React from 'react';
import styles from './nonStandardVinWarning.module.scss';

export const NonStandardVinWarning: React.FC<WarningProps> = ({ vin }) => {
    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <div className={styles.main}>
                    <div>VIN you entered</div>
                    <div className={styles.vin}>{vin || '-'}</div>
                    <div>does not seem to be a standard VIN</div>
                </div>
                <hr />
                <h4>What is a standard VIN?</h4>
                <p>
                    A standard VIN is always 17 characters long made up of
                    numbers and capital letters excluding letters I, O and Q.
                </p>
                <hr />
                <h4>Non-standard VIN</h4>
                <p>
                    If your vehicle was manufactured prior to 1 January 1989 or
                    imported from overseas, you might not have a standard VIN.
                </p>
            </div>
        </div>
    );
};

interface WarningProps {
    vin: string;
}
