import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './sharedControl.module.scss';
import { InfoStep } from './infoStep';
import { NonStandardVinWarning } from './nonStandardVinWarning';
import { BrowserBarcodeReader } from '@zxing/library';
import { validateVin, adjustVin } from '../../tools/vins';
import { VerifyCompliancePlateItem } from '../../models/schema/item';
import { VerifyCompliancePlateItemResponse } from '../../models/submissions/itemResponse';
import { MonthOptions, range } from '../../tools/selectOptions';
import { ImageViewer } from '../core/imageViewer';
import { Input } from '../core/input';
import { Select } from '../core/select';
import { ImageUpload } from '../core/imageUpload';
import { useImageStatus } from '../../store/images/hooks';
import { Page, PageMain } from '../core/page';
import { Button } from '../core/button';
import { PlateGuide } from '../core/plateGuide';
import { RootState } from '../../store';

type Props = {
    item: VerifyCompliancePlateItem;
    response?: VerifyCompliancePlateItemResponse;
    onExit: () => void;
    onConfirm: (data: VerifyCompliancePlateItemResponse) => void;
    readOnly?: boolean;
};

enum Steps {
    Info,
    PlateGuide,
    Photo,
    Summary,
    NonStandard,
    MissingInfo,
}

const currentYear = new Date().getFullYear();
const years = range(currentYear, currentYear - 99);

export const VerifyCompliancePlateControl: React.FC<Props> = ({ item, response, onExit, onConfirm, readOnly }) => {
    const [image, setImage] = useState(response ? response.image : undefined);

    const imageIsValid = useImageStatus(image);

    let identifierName: string;

    switch (item.vehicleType) {
        case 'boat':
            identifierName = 'HIN';
            break;
        default: // car
            identifierName = 'VIN';
    }

    const isCar = item.vehicleType === 'car' || !item.vehicleType;

    const { vehicleIdentity } = useSelector((state: RootState) => state.sharedData);
    const nevdisVin = vehicleIdentity?.nevdisResult?.vin;

    const [identifier, setIdentifier] = useState(response?.identifier ?? nevdisVin ?? '');
    const [systemIdentifier, setSystemIdentifier] = useState(response?.systemIdentifier ?? nevdisVin ?? '');
    const [month, setMonth] = useState(response?.month?.toString() ?? '');
    const [year, setYear] = useState(response?.year?.toString() ?? '');

    // steps
    const [step, setCurrentStep] = useState(response ? Steps.Summary : Steps.Info);
    const [previousStep, setPreviousStep] = useState<Steps | null>(null);
    const setStep = (s: Steps) => {
        if (s === previousStep) {
            setPreviousStep(0);
        }
        else {
            setPreviousStep(step);
        }

        setCurrentStep(s);
    }

    const handleBack = () => {
        if (step === Steps.Info || response) {
            onExit();
        } else if (step === Steps.Summary && !image) {
            setStep(Steps.PlateGuide);
        } else if (step === Steps.MissingInfo) {
            setStep(Steps.Summary);
        } else {
            setStep(previousStep == null ? (step - 1) : previousStep);
        }
    };

    const scanBarcode = (file: File) => {
        // skip if VIN already pre-filled
        if (nevdisVin) return;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
            const codeReader = new BrowserBarcodeReader();
            try {
                const result = await codeReader.decodeFromImageUrl(reader.result as string);
                const value = result.getText();
                const vin = adjustVin(value);
                setSystemIdentifier(vin);
                setIdentifier(vin);
            } catch (e) {
                // ignore
            }
        };
    }

    const confirmDisabled = !image && !identifier;

    const checkResponse = () => {
        if (isCar && identifier && !validateVin(identifier) && step !== Steps.NonStandard) {
            setStep(Steps.NonStandard);
            return;
        } else if (!image || !identifier || (!item.hideDate && !(month && year))) {
            setStep(Steps.MissingInfo);
            return;
        }
        handleSubmit();
    };

    const handleSubmit = () => {
        onConfirm({
            itemType: item.itemType,
            Type: 'Inspections.VerifyCompliancePlateItemResponse',
            image: image,
            identifier: identifier,
            systemIdentifier: systemIdentifier,
            month: parseInt(month),
            year: parseInt(year),
            hasPlate: !!image,
            isStandard: validateVin(identifier),
        });
    };

    const handleSpaceKey = (
        e: React.KeyboardEvent<HTMLInputElement>
    ) => { if (e.code === 'Space') e.preventDefault() };

    const infoText = `Take a photo of the ${item.display.toLowerCase()} on your vehicle. ${isCar ? ` Or follow the guide to locate the ${item.display.toLowerCase()}.` : ''}`;

    const getStep = () => {
        switch (step) {
            case Steps.Info:
                return (
                    <>
                    <PageMain center>
                        <InfoStep
                            image="/images/compliance-verify.svg"
                            text={infoText}
                        />
                    </PageMain>
                    <Button color="blue" onClick={() => setStep(Steps.Photo)}>Start</Button>
                    {(isCar) && (
                        <Button color="outline" onClick={() => setStep(Steps.PlateGuide)} marginTop="15px">Locate my {item.display.toLowerCase()}</Button>
                    )}
                    </>
                );
            case Steps.PlateGuide:
                return (
                    <PlateGuide
                        onStart={() => setStep(Steps.Photo)}
                        onCannotFind={() => setStep(Steps.Summary)}
                        cannotFindText="Cannot find my compliance plate"
                    />
                );
            case Steps.Photo:
                return (
                    <>
                    <PageMain>
                        <ImageUpload
                            label={`Photo of ${item.display.toLowerCase()}`}
                            images={image ? [image] : []}
                            itemId={item.id}
                            onChange={images => setImage(images[0])}
                            onDelete={() => setImage(undefined)}
                            onAddRaw={files => scanBarcode(files[0])}
                            readOnly={readOnly}
                            noUpload
                        />
                    </PageMain>
                    <Button color="green" onClick={() => setStep(Steps.Summary)} disabled={!imageIsValid}>
                        Next
                    </Button>
                    </>
                );
            case Steps.Summary:
                return (
                    <>
                    <PageMain>
                        <ImageViewer
                            images={image ? [image] : []}
                            onClick={() => setStep(Steps.Photo)}
                        />
                        <Input
                            label={identifierName}
                            value={identifier}
                            onChange={e => setIdentifier(e.target.value)}
                            disabled={readOnly}
                            onKeyDown={handleSpaceKey}
                            // removed input transform because it was acting crazy with non-english mobile keyboards
                            // onChange={e => setIdentifier(e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ''))}
                        />
                        {isCar && (
                            <p className={styles.note}>
                                A standard VIN is always 17 characters long made up of numbers and capital letters excluding letters I, O and Q.
                            </p>
                        )}
                        {item.hideDate ? null :
                            <div className={styles.doubleInputs}>
                                <Select
                                    label="Date"
                                    required
                                    value={month}
                                    onChange={e => setMonth(e.target.value)}
                                    disabled={readOnly}
                                >
                                    <option value="" disabled>Month</option>
                                    {MonthOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Select>
                                <Select
                                    required
                                    value={year}
                                    onChange={e => setYear(e.target.value)}
                                    disabled={readOnly}
                                >
                                    <option value="" disabled>Year</option>
                                    {years.map(year => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        }
                        </PageMain>
                        <Button color="green" onClick={checkResponse} disabled={readOnly || confirmDisabled}>Confirm</Button>
                    </>
                );
            case Steps.NonStandard:
                return (
                    <>
                        <PageMain>
                            <NonStandardVinWarning vin={identifier} />
                        </PageMain>
                        <Button color="blue" onClick={checkResponse}>Continue with entered VIN</Button>
                    </>
                );
            case Steps.MissingInfo:
                return (
                    <>
                        <PageMain center>
                            <InfoStep
                                image="/images/icon-missinginfo.svg"
                                text="We noticed some information is missing, are you sure you want to proceed."
                            />
                        </PageMain>
                        <Button color="blue" onClick={handleSubmit}>Confirm</Button>
                        <Button color="outline" marginTop="15px" onClick={() => setStep(Steps.Summary)}>Back</Button>
                    </>
                );
            default:
                return <div>Oops. Something went wrong. Try reloading the app.</div>;
        }
    };

    return (
        <Page title={item.display} onBack={handleBack}>
            {getStep()}
        </Page>
    );
};
