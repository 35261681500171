import * as React from 'react';
import styles from './loading.module.scss';

interface LoadingProps {
    text?: string;
}

// TODO: should use <Spinner />, but alignment issues

export class Loading extends React.Component<LoadingProps, {}> {
    public render() {
        return <div className={styles.loading}>
            <div className={styles.spinner}>
                <svg viewBox="25 25 50 50">
                    <circle cx="50" cy="50" r="20"></circle>
                </svg>
            </div>
            <div className={styles.label}>{this.props.text}</div>
        </div>;
    }
}
