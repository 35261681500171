import { Item } from './item';
import { Condition } from './condition';
import { Hint } from './hint';
import { SurveySubmission } from '../submissions';

export interface Order {
    organisationId: number;
    organisationName: string;
    orderId: number;
    properties: {};
    schemas: Schema[];
    tasks: Task[];
    hash: string;
    readOnly?: boolean;
}

export interface Schema {
    id: number;
    organisationId: number;
    iconGuid: string;
    version: number;
    name: string;
    display: string;
    surveyType: string;
    surveyTypeId: number;
    sections: Section[];
}

export interface Section {
    id: number;
    name: string;
    display: string;
    sectionType: SectionType;
    ordinal: number;
    items: Item[];
    conditions: Condition[];
    hints: Hint[];
}

export interface Task {
    taskId: number;
    taskName: string;
    schemaId: number;
    properties: {};
    requireSignature: boolean;
    submissionGuid?: string;
    submission?: SurveySubmission;
    readOnly?: boolean;
}

export enum SectionType {
    unknown = 'Unknown', //0
    inspection = 'Inspection', //1
    preInspection = 'PreInspection', //2
    photos = 'Photos', //3
    confirmation = 'Confirmation', //4
    simpleInspection = 'SimpleInspection', //5
    sharedConfirmation = 'SharedConfirmation', //6
}
