import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GroupItem, Item, YesNoItem } from '../../models/schema/item';
import { GroupItemResponse, ItemResponse } from '../../models/submissions/itemResponse';
import { RootState } from '../../store';
import { applicationActionCreators } from '../../store/appState/actionCreators';
import { ItemType } from '../../models/itemTypes';
import { GroupTextControl } from './groupTextControl';
import { GroupPhotoControl } from './groupPhotoControl';
import { GroupYesNoControl } from './groupYesNoControl';
import { GroupDateControl } from './groupDateControl';
import { GroupDropDownControl } from './groupDropDownControl';
import { Page, PageMain } from '../core/page';
import { Button } from '../core/button';
import { GroupMediaControl } from './groupMediaControl';

type Props = {
    item: GroupItem;
    onExit: () => void;
    onConfirm: (data: GroupItemResponse) => void;
    orderId: string;
    taskId: string;
    readOnly?: boolean;
}

type Params = {
    orderId: string;
    taskId: string;
    itemId: string;
};

type GroupResponse = {
    [itemId: string]: ItemResponse | undefined;
}

export const GroupControl = (props: Props) => {
    const params = useParams<Params>();
    const orderId = parseInt(params.orderId);
    const taskId = parseInt(params.taskId);

    const groupItems = props.item.items;

    const readOnly = props.readOnly;
    const savedResponses = useSelector((state: RootState) => {
        const res: GroupResponse = {};
        groupItems.forEach(item => {
            const resSubmission = state.appState.responses[orderId][taskId][item.id];
            res[item.id] = resSubmission ? resSubmission.response : undefined;
        });
        return res;
    })
    const [groupResponse, setGroupResponse] = useState<GroupResponse>(savedResponses);

    const handleChange = (itemId: number, response: ItemResponse) => {
        const newGroupResponse = {
            ...groupResponse,
            [itemId]: response,
        };
        setGroupResponse(newGroupResponse);

        setGroupResponseIsValid(detectValidity(newGroupResponse));
    };

    // TODO: need to verify these conditions, e.g. we don't check `item.required` flag sometimes?
    const detectValidity = (gResponse: GroupResponse) => {
        const valid = Object.entries(gResponse).every(([itemId, response]) => {
            const item = groupItems.find(item => item.id === parseInt(itemId));

            if (!response) {
                return !(item?.required);
            }

            switch (response.itemType) {
                case ItemType.text:
                    // value isn't an empty string
                    if (!response.value) {
                        return !(item?.required);
                    }
                    return true;
                case ItemType.dropDown:
                    return response.values.length !== 0;
                case ItemType.photo:
                    // image exists and is not uploading or failed to upload
                    if (!response.image) {
                        return !(item?.required);
                    }
                    const { imageGuid } = response.image;

                    return imagesState[imageGuid] && !imagesState[imageGuid].isUploading && !imagesState[imageGuid].error;
                case ItemType.yesNo:
                    const yesNoItem = item as YesNoItem;
                    // not done if nothing selected
                    if (response.value === yesNoItem.nilValue) return false;
                    // image not required if `noValue` is selected
                    if (response.value === yesNoItem.noValue) return true;
                    if (!yesNoItem.photoDisplay) {
                        // image is not required
                        return true;
                    } else {
                        // image is required
                        if (!response.images || response.images.length === 0) return false;
                        // TODO: should allow if some images failed but at least 1 succeeded
                        // check if any images are still uploading or failed
                        return response.images.every(image => {
                            const { imageGuid } = image;
                            return !!imagesState[imageGuid] && !imagesState[imageGuid].isUploading && !imagesState[imageGuid].error;
                        });
                    }
                default:
                    return true;
            };
        });

        return valid;
    };

    // haven't tried, but don't think custom hook `useImageStatus()` can be used
    // here as the hook will be called conditionally
    const imagesState = useSelector((state: RootState) => state.images);
    const [groupResponseIsValid, setGroupResponseIsValid] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        setGroupResponseIsValid(detectValidity(groupResponse));
    }, [imagesState]);

    const saveAndGoBack = () => {
        Object.entries(groupResponse).forEach(([itemId, response]) => {
            dispatch(applicationActionCreators.saveTaskItem(orderId, taskId, parseInt(itemId), response!)); // button is disabled if !response
        });

        const response: GroupItemResponse = {
            Type: "Inspections.GroupItemResponse",
            itemType: ItemType.group,
        };
        dispatch(applicationActionCreators.saveTaskItemAndUpdateProgress(orderId, taskId, props.item.id, response));

        history.goBack();
    };

    const renderGroupControl = (item: Item) => {
        const props = {
            item: item as any,
            response: groupResponse[item.id] as any,
            onChange: (res: ItemResponse) => handleChange(item.id, res),
            readOnly: readOnly
        };

        switch (item.itemType) {
            case ItemType.text:
                return <GroupTextControl {...props} />;
            case ItemType.dropDown:
                return <GroupDropDownControl {...props} />;
            case ItemType.photo:
                return <GroupPhotoControl {...props} />;
            case ItemType.yesNo:
                return <GroupYesNoControl {...props} />;
            case ItemType.date:
                return <GroupDateControl {...props} />;
            case ItemType.media:
                return <GroupMediaControl {...props} />;
            default:
                return <p>Item type {item.itemType} isn't supported.</p>;
        }
    }

    return (
        <Page title={props.item.display} onBack={props.onExit}>
            <PageMain>
                {groupItems.map((item, i) => (
                    <React.Fragment key={item.id}>
                        {renderGroupControl(item)}
                        {i !== groupItems.length - 1 &&
                            item.itemType !== ItemType.text &&
                            item.itemType !== ItemType.date &&
                            item.itemType !== ItemType.dropDown &&
                            <hr />
                        }
                    </React.Fragment>
                ))}
            </PageMain>
            <Button color="green" onClick={saveAndGoBack} disabled={readOnly || !groupResponseIsValid}>Save</Button>
        </Page>
    );
}
