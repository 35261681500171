import React, { useState, useRef } from 'react';
import './imageSlideSearcher.scss';

type Props = {
  images: Image[];
};

type Image = {
  label: string;
  url: string;
};

export const ImageSlideSearcher = (props: Props) => {
  const [current, setCurrent] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const scrollSnapped = (entries: IntersectionObserverEntry[]) => {
    if (entries.length === 1) {
      const el = entries[0].target as HTMLImageElement;
      const index = parseInt(el.dataset.index!);
      setCurrent(index);
    }
  };

  const observerRef = useRef(
    new IntersectionObserver(scrollSnapped, {
      root: containerRef.current,
      threshold: 0.75,
    })
  );

  const prev = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        top: 0,
        left: -containerRef.current.clientWidth,
        behavior: 'smooth',
      });
    }
  };

  const next = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        top: 0,
        left: containerRef.current.clientWidth,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="image-slide-searcher">
      <div className="image-container" ref={containerRef}>
        {props.images.map((img, i) => {
          return (
            <img
              data-index={i}
              key={i}
              ref={el => {
                if (el) observerRef.current.observe(el);
              }}
              src={img.url}
              alt={img.label}
              title={img.label}
            />
          );
        })}
      </div>
      <div className="image-label-container">
        <button onClick={prev} disabled={current === 0}>
          <img src="/images/icon-arrow-left.svg" alt="Prev" />
        </button>
        <label>{props.images[current].label}</label>
        <button onClick={next} disabled={current === props.images.length - 1}>
          <img src="/images/icon-arrow-right.svg" alt="Next" />
        </button>
      </div>
    </div>
  );
};
