import React from 'react';
import { Input } from '../core/input';
import { TextItem } from '../../models/schema/item';
import { TextItemResponse } from '../../models/submissions/itemResponse';
import { ItemType } from '../../models/itemTypes';

type Props = {
  item: TextItem;
  response?: TextItemResponse;
  onChange: (response: TextItemResponse) => void;
  readOnly?: boolean;
};

export const GroupTextControl = (props: Props) => {
  return (
    <Input
      label={props.item.display}
      value={props.response ? props.response.value : ''}
      onChange={e =>
        props.onChange({
          Type: 'Inspections.TextItemResponse',
          itemType: ItemType.text,
          value: e.target.value,
        })
      }
      disabled = {props.readOnly}
    />
  );
};
