import { useSelector } from 'react-redux';
import { RootState } from '..';
import { Image } from '../../models/submissions/image';
import { Media } from '../../models/submissions/media';

// maybe this should be defined as part of the state
type ImageStatus = 'done' | 'uploading' | 'error' | 'unknown';

export const useImages = (images: Image[]) => {
  return useSelector((state: RootState) =>
    images.map(image => {
      if (image.publicUrl && !state.images[image.imageGuid]) {
        return {
          guid: image.imageGuid,
          url: image.publicUrl,
          isUploading: false,
          error: ''
        }
      }

      return {
        guid: image.imageGuid,
        ...state.images[image.imageGuid],
      };
    })
  );
};

export const useImageStatus = (image?: Image | Media) => {
  const imagesState = useSelector((state: RootState) => state.images);

  if (!image) return false;
  if (image.Type != 'Image') return false;

  let guid = (image as Image).imageGuid || (image as Media).mediaGuid;
  
  if (image.publicUrl && !imagesState[guid]) return true;
  
  const { isUploading, error } = imagesState[guid];
  if (isUploading || error) return false;

  return true;
};

// replace use of this with below
export const useImagesStatus = (images: (Image | undefined)[]) => {
  const imagesState = useSelector((state: RootState) => state.images);

  return images.every(image => {
    if (!image) return false;
    if (image.publicUrl && !imagesState[image.imageGuid]) return true;

    const { isUploading, error } = imagesState[image.imageGuid];
    if (isUploading || error) return false;

    return true;
  });
};

export function useImageStatuses(imageGuids: string[]): ImageStatus[] {
  const imagesState = useSelector((state: RootState) => state.images);

  return imageGuids.map(guid => {
    const image = imagesState[guid];
    if (!image) return 'unknown';
    if (image.isUploading) return 'uploading';
    else if (image.error) return 'error';
    else return 'done';
  });
}

export function useImageStatusV2(imageGuid?: string): ImageStatus {
  const imagesState = useSelector((state: RootState) => state.images);

  if (!imageGuid) return 'unknown';
  const image = imagesState[imageGuid];
  if (!image) return 'unknown';
  if (image.isUploading) return 'uploading';
  else if (image.error) return 'error';
  else return 'done';
}
