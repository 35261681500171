import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { applicationActionCreators } from '../store/appState/actionCreators';
import { TaskList } from './taskList';
import { RouteComponentProps, Redirect, useHistory } from 'react-router-dom';
import { getCurrentPosition } from '../tools/geolocation';
import { RootState } from '../store';
import styles from './inspectionMain.module.scss';
import { Page, PageMain } from './core/page';
import { InfoStep } from './items/infoStep';
import successIcon from './icons/success.svg';
import { Button } from './core/button';
import { Loading } from './core/loading';
import { ApplicationStateKnownAction } from '../store/appState/actions';
import { requestCameraPermission } from '../tools/cameraPermission';
import { ItemType } from '../models/itemTypes';
import { GroupItem } from '../models/schema/item';

type MatchParams = {
    orderId: string;
};

export const InspectionMainView: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
    const dispatch = useDispatch();

    // clear the position remembered after exiting task page
    useEffect(() => {
        dispatch<ApplicationStateKnownAction>({ type: 'CLEAR_LAST_VIEWED_POSITION' });
    }, [dispatch]);

    const { orderId } = match.params;

    const orderExist = useSelector((state: RootState) => state.appState.orders[orderId]);

    const groupItems = orderExist.schemas
        .flatMap(schema => schema.sections)
        .flatMap(section => section.items)
        .filter(x => x.itemType === ItemType.group)
        .flatMap(x => (x as GroupItem).items);
        
    const locationRequested = orderExist.schemas
        .flatMap(schema => schema.sections)
        .flatMap(section => section.items)
        .some(item => item.itemType == ItemType.gps);

    const cameraRequested = groupItems.some(x => x.itemType == ItemType.media && x.allowsFromLibrary === false);

    var taskPermissions = "";
    if (locationRequested && cameraRequested){
        taskPermissions = "Device with camera and GPS location required";
    }
    else if (locationRequested && !cameraRequested){
        taskPermissions = "Device with GPS location required";
    }
    else if (!locationRequested && cameraRequested){
        taskPermissions = "Device with camera required";
    }
    const allDone = useSelector((state: RootState) => {
        if (!state.appState.orders[orderId]) return false;

        return state.appState.orders[orderId].tasks.every(task => task.submissionGuid && !task.submission);
    });

    const selectTask = async (taskId: number) => {
        let coords: GeolocationCoordinates | undefined;
        let cameraPermissionGranted = false;
        try {
            const pos = await getCurrentPosition();
            coords = pos.coords;
            if(cameraRequested){
                cameraPermissionGranted = await requestCameraPermission();
            }
        } catch (e) {}
        dispatch(
            applicationActionCreators.startTask(
                parseInt(orderId),
                taskId,
                coords ? { latitude: coords?.latitude, longitude: coords?.longitude, accuracy: coords?.accuracy } : undefined,
                cameraPermissionGranted)
        );
        history.push(`/order/${orderId}/task/${taskId}`);
    };

    const history = useHistory();
    const fetchingOrder = useSelector((state: RootState) => state.appState.fetchingOrder);

    useEffect(() => {
        if (history.action === "POP") {
            dispatch(applicationActionCreators.refreshOrder(parseInt(orderId)));
        }
    }, [dispatch, history, orderId]);

    const [showFinish, setShowFinish] = useState(false);

    if (allDone) {
        setTimeout(() => setShowFinish(true), 1000);
    }

    if (!orderExist) return <Redirect to="/" />;
    if (fetchingOrder) return <Loading />;

    return (
        <>
            <div className="splash-content transparent">
                <div className={styles.taskNote}>Select task to begin</div>
                <div className={styles.taskPermission}>{taskPermissions}</div>
                <TaskList orderId={parseInt(orderId)} onSelect={selectTask} />
            </div>
            {(allDone || showFinish) ? <Page noBar className={cn(styles.finish, { [styles.finishShow]: showFinish })}>
                <PageMain center>
                    <InfoStep image={successIcon} text="You're all done!" />
                </PageMain>
                <Button color="green" onClick={() => {
                    history.push('/');
                    dispatch(applicationActionCreators.logout());
                }}>Finish</Button>
            </Page> : ''}
        </>
    );
};
