import React, { useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import styles from './taskList.module.scss';
import { _apiUrl } from '../settings';
import { RootState } from '../store';
import { ReactComponent as ArrowRight } from './icons/arrow-right.svg';
import { ReactComponent as ItemCheck } from './icons/item-check.svg';
import { Spinner } from './core/spinner';

export const TaskList: React.FC<Props> = ({ orderId, onSelect }) => {
    const [loadingTask, setLoadingTask] = useState(-1);

    const tasks = useSelector((state: RootState) => {
        const order = state.appState.orders[orderId];
        if (!order) return [];

        return order.tasks.map(task => {
            // map to schema to get icon GUID
            const schema = order.schemas.find(schema => schema.id === task.schemaId)!;

            return {
                id: task.taskId,
                name: schema.display,
                iconGuid: schema.iconGuid,
                completed: !!task.submissionGuid,
            };
        });
    });

    return (
        <div className={cn(styles.container, { [styles.limit]: tasks.length > 3 })}>
            <div className={styles.list}>
                {tasks.map(task => (
                    <button key={task.id} onClick={() => {
                        setLoadingTask(task.id);
                        onSelect(task.id);
                    }}>
                        {task.iconGuid && <img className={styles.icon} src={`${_apiUrl}/icons/${task.iconGuid}`} alt={task.name} />}
                        <div>{task.name}</div>
                        {task.completed ? <ItemCheck /> : task.id === loadingTask ? <Spinner small /> : <ArrowRight />}
                    </button>
                ))}
            </div>
        </div>
    );
};

type Props = {
    orderId: number;
    onSelect: (taskId: number) => void;
};
