import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '..';
import { getVehicleInfo } from '../../api/carzam';
import { VehicleIdentity } from '../../models/vehicleIdentity';

type VehicleIdStatus = 'pending' | 'success' | 'failure';

type SharedDataState = {
  vehicleIdStatus: VehicleIdStatus;
  vehicleIdentity?: VehicleIdentity;
};

const initialState: SharedDataState = {
  vehicleIdStatus: 'pending',
};

const sharedDataSlice = createSlice({
  name: 'sharedData',
  initialState,
  reducers: {
    identifyVehicleStart(state) {
      state.vehicleIdStatus = 'pending';
      state.vehicleIdentity = undefined;
    },
    identifyVehicleSuccess(state, action: PayloadAction<VehicleIdentity>) {
      state.vehicleIdStatus = 'success';
      state.vehicleIdentity = action.payload;
    },
    identifyVehicleFailure(state) {
      state.vehicleIdStatus = 'failure';
    },
  },
});

const {
  identifyVehicleStart,
  identifyVehicleSuccess,
  identifyVehicleFailure,
} = sharedDataSlice.actions;

export default sharedDataSlice.reducer;

export const identifyVehicle = (
  orderId: number,
  imageGuid: string
): AppThunk => async (dispatch, getState) => {
  try {
    const hash = getState().appState.orders[orderId].hash;

    dispatch(identifyVehicleStart());

    const vehicleIdentity = await getVehicleInfo(hash, imageGuid);

    dispatch(identifyVehicleSuccess(vehicleIdentity));
  } catch (e) {
    dispatch(identifyVehicleFailure());
  }
};
