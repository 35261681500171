import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import styles from './page.module.scss';
import { ReactComponent as ArrowLeft } from '../icons/arrow-left.svg';

type PageProps = {
  title?: string;
  onBack?: () => void;
  noBar?: boolean;
  className?: string; // custom styles applied to page root div
};

type PageMainProps = {
  center?: boolean;
};

export const Page = React.forwardRef<HTMLElement, PropsWithChildren<PageProps>>(
  (props, ref) => {
    const history = useHistory();

    return (
      <div className={cn(styles.page, props.className)}>
        {props.noBar ? null : (
          <header className={styles.nav}>
            <div className={styles.navContent}>
              <button
                className={styles.back}
                onClick={props.onBack ?? history.goBack}
              >
                <ArrowLeft />
                Back
              </button>
              {props.title && <h1>{props.title}</h1>}
            </div>
          </header>
        )}
        <main className={styles.contentWrapper} ref={ref}>
          <div className={styles.content}>{props.children}</div>
        </main>
      </div>
    );
  }
);

// use this as a direct child of <Page /> to fill remaining space
export const PageMain: React.FC<PageMainProps> = props => (
  <div className={cn(styles.main, { [styles.center]: props.center })}>
    {props.children}
  </div>
);
