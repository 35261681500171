import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './verifyVehicleControl.module.scss';
import fadeStyles from '../../styles/fade.module.scss';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Page, PageMain } from '../core/page';
import { Button } from '../core/button';
import { InfoStep } from './infoStep';
import { ImageUpload } from '../core/imageUpload';
import { ImageViewer } from '../core/imageViewer';
import { Input } from '../core/input';
import { Select } from '../core/select';
import { useImagesStatus } from '../../store/images/hooks';
import { identifyVehicle } from '../../store/sharedData/sharedDataSlice';
import { RootState } from '../../store';
import { ItemType } from '../../models/itemTypes';
import { VerifyVehicleItem } from '../../models/schema/item';
import { VerifyVehicleItemResponse } from '../../models/submissions/itemResponse';
import { Image } from '../../models/submissions/image';
import { ReactComponent as ScanningCar } from '../icons/scanning-car.svg';
import { ReactComponent as ScanningLine } from '../icons/scanning-line.svg';
import { ReactComponent as Success } from '../icons/success.svg';

type Props = {
  item: VerifyVehicleItem;
  response?: VerifyVehicleItemResponse;
  onExit: () => void;
  onConfirm: (response: VerifyVehicleItemResponse) => void;
  readOnly?: boolean;
};

type Images = {
  [name: string]: Image | undefined;
};

enum Steps {
  Info,
  Photos,
  Processing,
  Summary,
}

const ProcessingText = [
  'Identifying vehicle...',
  'Scanning registration...',
  'Matching data from NEVDIS...',
  'Matching data from PPSR...',
];

type Params = {
  orderId: string;
  taskId: string;
  itemId: string;
};

export const VerifyVehicleControl = (props: Props) => {
  const { item, response, onExit, onConfirm, readOnly } = props;

  const params = useParams<Params>();
  const orderId = parseInt(params.orderId);

  const dispatch = useDispatch();

  const [step, setStep] = useState(response ? Steps.Summary : Steps.Info);

  // response
  const [images, setImages] = useState<Images>(
    // map images to key-value pairs (photo name - image object)
    item.photoNames.reduce(
      (obj, name, i) => ({
        ...obj,
        [name]: response?.images?.[i],
      }),
      {}
    )
  );
  const [userResponse, setUserResponse] = useState({
    make: response?.make ?? '',
    model: response?.model ?? '',
    badge: response?.badge ?? '',
    series: response?.series ?? '',
    body: response?.body ?? '',
    rego: response?.rego ?? '',
    regoState: response?.regoState ?? '',
    colour: response?.colour ?? '',
    regoExpiryDate: response?.regoExpiryDate ? response.regoExpiryDate.split('T', 1)[0] : '',
  });
  const [systemResponse, setSystemResponse] = useState({
    systemMake: response?.systemMake ?? '',
    systemModel: response?.systemModel ?? '',
    systemBadge: response?.systemBadge ?? '',
    systemSeries: response?.systemSeries ?? '',
    systemRego: response?.systemRego ?? '',
    systemRegoState: response?.systemRegoState ?? '',
  });

  const imagesAreValid = useImagesStatus(Object.values(images));

  const { vehicleIdStatus, vehicleIdentity } = useSelector(
    (state: RootState) => state.sharedData
  );
  const [processingStep, setProcessingStep] = useState(0);
  const [processingDone, setProcessingDone] = useState(false);

  // cycle through the messages
  useEffect(() => {
    if (step === Steps.Processing) {
      if (processingStep !== ProcessingText.length - 1) {
        setTimeout(() => setProcessingStep(processingStep + 1), 1500);
      } else if (vehicleIdStatus === 'failure') {
        setStep(Steps.Summary);
      } else if (vehicleIdStatus === 'success') {
        setProcessingDone(true);
        setTimeout(() => setStep(Steps.Summary), 1500);
      }
    }
  }, [step, processingStep, vehicleIdStatus]);

  const handleBack = () => {
    if (step === Steps.Info || response) {
      onExit();
    } else if (step === Steps.Summary) {
      setStep(step - 2); // skip processing step
    } else {
      setStep(step - 1);
    }
  };

  const handleVehicleId = async () => {
    setStep(Steps.Processing);
    const uploadImageName = item.photoNames[item.uploadImageIndex];
    dispatch(identifyVehicle(orderId, images[uploadImageName]!.imageGuid));
  };

  useEffect(() => {
    if (vehicleIdStatus === 'success' && vehicleIdentity) {
      const data = vehicleIdentity;

      setSystemResponse({
        systemMake: data.vehicleResult?.make ?? '',
        systemModel: data.vehicleResult?.model ?? '',
        systemBadge: data.vehicleResult?.badge ?? '',
        systemSeries: data.vehicleResult?.series ?? '',
        systemRego: data.regoResult?.regoNumber ?? '',
        systemRegoState: data.regoResult?.regoState ?? '',
      });

      setUserResponse({
        make: data.vehicleResult?.make ?? '',
        model: data.vehicleResult?.model ?? '',
        badge: data.vehicleResult?.badge ?? '',
        series: data.vehicleResult?.series ?? '',
        rego: data.regoResult?.regoNumber ?? '',
        regoState: data.regoResult?.regoState ?? '',
        body: '',
        colour: '',
        regoExpiryDate: '',
      });
    }
  }, [vehicleIdStatus, vehicleIdentity]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => setUserResponse({ ...userResponse, [e.target.name]: e.target.value });

  const handleSpaceKey = (
        e: React.KeyboardEvent<HTMLInputElement>
  ) => { if (e.code === 'Space') e.preventDefault() };

  const handleSave = () =>
    onConfirm({
      Type: 'Inspections.VerifyVehicleItemResponse',
      itemType: ItemType.verifyVehicle,
      images: item.photoNames.map(name => images[name]!), // ordered by `photoNames`
      ...userResponse,
      ...systemResponse,
    });

  const getStep = () => {
    switch (step) {
      case Steps.Info:
        return (
          <>
            <PageMain center>
              <InfoStep
                image="/images/vehicle-verify.svg"
                text="Follow the guide to take photos around your car, making sure your registration plate is visible in frame."
              />
            </PageMain>
            <Button color="blue" onClick={() => setStep(Steps.Photos)}>
              Start
            </Button>
          </>
        );
      case Steps.Photos:
        return (
          <>
            <PageMain>
              {item.photoNames.map((name, i) => (
                <React.Fragment key={name}>
                  <ImageUpload
                    label={name}
                    images={images[name] ? [images[name]!] : []}
                    itemId={item.id}
                    onChange={imgs => setImages({ ...images, [name]: imgs[0] })}
                    onDelete={() => setImages({ ...images, [name]: undefined })}
                    readOnly={readOnly}
                    noUpload
                  />
                  {i !== item.photoNames.length - 1 && <hr />}
                </React.Fragment>
              ))}
            </PageMain>
            <Button
              color="green"
              onClick={handleVehicleId}
              disabled={!imagesAreValid}
            >
              Next
            </Button>
          </>
        );
      case Steps.Processing:
        return (
          <PageMain center>
            <div className={styles.icon}>
              {processingDone ? (
                <Success className={styles.success} />
              ) : (
                <>
                  <ScanningCar />
                  <ScanningLine className={styles.line} />
                </>
              )}
            </div>
            <SwitchTransition>
              <CSSTransition
                key={processingStep}
                classNames={{ ...fadeStyles }}
                addEndListener={(node, done) =>
                  node.addEventListener('transitionend', done, false)
                }
              >
                <p className={styles.text}>
                  {processingDone
                    ? 'Pre-filling data...'
                    : ProcessingText[processingStep]}
                </p>
              </CSSTransition>
            </SwitchTransition>
          </PageMain>
        );
      case Steps.Summary:
        return (
          <>
            <PageMain>
              <ImageViewer
                images={item.photoNames.map(name => images[name]!)}
                onClick={() => setStep(Steps.Photos)}
              />
              <Input
                label="Make"
                name="make"
                value={userResponse.make}
                onChange={handleInputChange}
                disabled={readOnly}
              />
              <Input
                label="Model"
                name="model"
                value={userResponse.model}
                onChange={handleInputChange}
                disabled={readOnly}
              />
              <Input
                label="Badge"
                name="badge"
                value={userResponse.badge}
                onChange={handleInputChange}
                disabled={readOnly}
              />
              <Input
                label="Body type"
                name="body"
                value={userResponse.body}
                onChange={handleInputChange}
                disabled={readOnly}
              />
              <Input
                label="Series (optional)"
                name="series"
                value={userResponse.series}
                onChange={handleInputChange}
                disabled={readOnly}
              />
              <Input
                label="Colour (optional)"
                name="colour"
                value={userResponse.colour}
                onChange={handleInputChange}
                disabled={readOnly}
              />
              <Input
                label="Registration"
                name="rego"
                value={userResponse.rego}
                onChange={handleInputChange}
                disabled={readOnly}
                onKeyDown={handleSpaceKey}
              />
              <Input
                label="Registration expiry date"
                type="date"
                placeholder="yyyy-mm-dd" // for desktop safari
                name="regoExpiryDate"
                value={userResponse.regoExpiryDate}
                onChange={handleInputChange}
                disabled={readOnly}
              />
              <Select
                label="Registration state"
                name="regoState"
                required
                value={userResponse.regoState}
                onChange={handleInputChange}
                disabled={readOnly}
              >
                <option value="" disabled>
                  Select state
                </option>
                <option value="NSW">NSW</option>
                <option value="VIC">VIC</option>
                <option value="QLD">QLD</option>
                <option value="WA">WA</option>
                <option value="SA">SA</option>
                <option value="TAS">TAS</option>
                <option value="ACT">ACT</option>
                <option value="NT">NT</option>
              </Select>
            </PageMain>
            <Button color="green" onClick={handleSave} disabled={readOnly}>
              Confirm
            </Button>
          </>
        );
      default:
        return <p>Oops. Something went wrong. Try reloading the app.</p>;
    }
  };

  return (
    <Page title={item.display} onBack={handleBack}>
      {getStep()}
    </Page>
  );
};
