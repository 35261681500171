import React from 'react';
import { RootState } from '../store';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ItemType } from '../models/itemTypes';
import { VerifyVehicleControl } from './items/verifyVehicleControl';
import { VerifyOdometerControl } from './items/verifyOdometerControl';
import { VerifyCompliancePlateControl } from './items/verifyCompliancePlateControl';
import { VerifyBuildPlateControl } from './items/verifyBuildPlateControl';
import { PhotoControl } from './items/photoControl';
import { GroupControl } from './items/groupControl';
import { LocationControl } from './items/locationControl';
import { LivePhotoControl } from './items/livePhotoControl';
import { VerifyNccControl } from './items/verifyNccControl';
import {
    VerifyVehicleItemResponse,
    VerifyOdometerItemResponse,
    VerifyBuildPlateItemResponse,
    VerifyCompliancePlateItemResponse,
    PhotoItemResponse,
    ItemResponse,
    LocationItemResponse,
    LivePhotoItemResponse,
    VerifyNccIdCheckResponse,
    MediaItemResponse,
} from '../models/submissions/itemResponse';
import { applicationActionCreators } from '../store/appState/actionCreators';
import { Page } from './core/page';
import { MediaControl } from './items/mediaControl';

type Params = {
    orderId: string;
    taskId: string;
    itemId: string;
};

export const InspectionItem = () => {
    const params = useParams<Params>();
    const orderId = parseInt(params.orderId);
    const taskId = parseInt(params.taskId);
    const itemId = parseInt(params.itemId);

    const history = useHistory();

    const schema = useSelector((state: RootState) => {
        const order = state.appState.orders[orderId];
        
        if (!order) {
            history.goBack();
        }

        const task = order.tasks.find(task => task.taskId === taskId)!;
        return order.schemas.find(schema => schema.id === task.schemaId)!;
    });
    const order = useSelector((state: RootState) => {
        return state.appState.orders[orderId];
    });

    const readOnly = useSelector((state: RootState) => {
        const task = order.tasks.find(task => task.taskId === taskId)!;
        return task?.readOnly;
    });

    const item = schema.sections.flatMap(section => section.items).find(item => item.id === itemId)!;

    const { responses } = useSelector((state: RootState) => state.appState);

    const dispatch = useDispatch();

    const handleSave = (response: ItemResponse, stayIn?: boolean, callback?: () => void) => {
        dispatch(applicationActionCreators.saveTaskItemAndUpdateProgress(orderId, taskId, itemId, response));

        // callback only fires in the case where the item is done
        if (item.autoSubmit) {
            dispatch(applicationActionCreators.submitTask(orderId, taskId, callback));
        }
        else if (!stayIn) {
            history.goBack();

            if (callback)
                callback();
        }
    };

    // redirect to order page on refresh
    if (!responses[orderId][taskId]) return <Redirect to={`/order/${orderId}`} />;

    const response = responses[orderId][taskId][itemId].response;

    return <InspectionItemContent
        onExit={() => history.goBack()}
        onConfirm={handleSave}
        taskId={taskId.toString()}
        itemId={itemId.toString()}
        orderId={orderId.toString()}
        item={item}
        response={response as ItemResponse}
        hash={order.hash}
        readOnly={readOnly}
    />;
};

export const InspectionItemContent: React.FC<InspectionItemProps> = (props) => {
    const response = props.response;

    switch (props.item.itemType) {
        case ItemType.verifyVehicle:
            return <VerifyVehicleControl {...props} response={response as VerifyVehicleItemResponse} />;
        case ItemType.verifyOdometer:
            return <VerifyOdometerControl {...props} response={response as VerifyOdometerItemResponse} />;
        case ItemType.verifyCompliancePlate:
            return <VerifyCompliancePlateControl {...props} response={response as VerifyCompliancePlateItemResponse} />
        case ItemType.verifyBuildPlate:
            return <VerifyBuildPlateControl {...props} response={response as VerifyBuildPlateItemResponse} />
        case ItemType.photo:
            return <PhotoControl {...props} response={response as PhotoItemResponse} />;
        case ItemType.media:
            return <MediaControl {...props} response={response as MediaItemResponse} />;
        case ItemType.group:
            return <GroupControl {...props} />;
        case ItemType.location:
            return <LocationControl {...props} response={response as LocationItemResponse} />;
        case ItemType.livePhoto:
            return <LivePhotoControl {...props} response={response as LivePhotoItemResponse} />;
        case ItemType.verifyNccIdCheck:
            return <VerifyNccControl {...props} response={response as VerifyNccIdCheckResponse} />;
        default:
            console.log(props.item.itemType);
            return <Page>Item type not supported</Page>;
    }
}

interface InspectionItemProps {
    item: any;
    response: ItemResponse;
    taskId: string;
    itemId: string;
    orderId: string;
    hash: string;
    onConfirm: (response: ItemResponse, stayIn?: boolean, callback?: () => void) => void;
    onExit: () => void;
    inGroup?: boolean;
    readOnly?: boolean;
}
