import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from './taskPage.module.scss';
import { RootState } from '../store';
import { applicationActionCreators } from '../store/appState/actionCreators';
import { SectionType } from '../models/schema';
import { getCurrentPosition } from '../tools/geolocation';
import { Loading } from './core/loading';
import { GroupItem, Item } from '../models/schema/item';
import { ItemType } from '../models/itemTypes';
import { ConfirmationSection } from './confirmationSection';
import { ReactComponent as ArrowRight } from './icons/arrow-right.svg';
import { ReactComponent as ItemCheck } from './icons/item-check.svg';
import { Page, PageMain } from './core/page';
import { Spinner } from './core/spinner';
import { Button } from './core/button';
import { InfoStep } from './items/infoStep';
import successIcon from './icons/success.svg';
import warningIcon from './icons/warning.svg';
import { ApplicationStateKnownAction } from '../store/appState/actions';
import { DevicePermissions } from './devicePermissions';
import { requestCameraPermission } from '../tools/cameraPermission';
import { _locationAccuracy } from '../settings';

type Params = {
  orderId: string;
  taskId: string;
};

export const TaskPage = () => {
  const [locationGranted, setLocationGranted] = useState<boolean | undefined>();
  const [cameraGranted, setCameraGranted] = useState<boolean | undefined>();
  const [locationAccurate, setLocationAccurate] = useState<boolean | undefined>();
  const [firstLocationCheck, setFirstLocationCheck] = useState(true);

  const params = useParams<Params>();
  const orderId = parseInt(params.orderId);
  const taskId = parseInt(params.taskId);

  const history = useHistory();

  const schema = useSelector((state: RootState) => {
    const order = state.appState.orders[orderId];
    const task = order.tasks.find(task => task.taskId === taskId)!;
    return order.schemas.find(schema => schema.id === task.schemaId)!;
  });

  const readOnly = useSelector((state: RootState) => {
    return !!state.appState.submissionStatus[orderId][taskId]?.readOnly;
  });

  const { responses, submissionStatus } = useSelector(
    (state: RootState) => state.appState
  );

  // scroll restoration... this is quite hacky
  const scrollContainerRef = useRef<HTMLElement>(null);

  const lastViewedPosition = useSelector(
    (state: RootState) => state.appState.lastViewedPosition
  );

  const groupItems = schema.sections
    .flatMap(section => section.items)
    .filter(x => x.itemType === ItemType.group)
    .flatMap(x => (x as GroupItem).items);

  const cameraRequested = groupItems.some(x => x.itemType == ItemType.media && x.allowsFromLibrary === false);

  const locationRequested = schema.sections
    .flatMap(section => section.items)
    .some(item => item.itemType == ItemType.gps)

  useEffect(() => {
    if (lastViewedPosition && loadingItem === -1) {
      const el = scrollContainerRef.current;
      if (el) {
        el.scrollTop = lastViewedPosition;
      }
    }

    //Check location permission
    if (locationRequested) {
      let overrideTimeout: number | undefined = undefined;
      if (firstLocationCheck) {
        overrideTimeout = 0;
        setFirstLocationCheck(false);
      }

      getCurrentPosition(overrideTimeout).then(pos => {
        setLocationGranted(true);
        if (pos.coords.accuracy <= _locationAccuracy) {
          setLocationAccurate(true);
        }
        else
          setLocationAccurate(false);
      }).catch((err) => {
        setLocationGranted(false);
      });
    }
    else
      setLocationGranted(true);

    //Check camera permission
    if (cameraRequested) {
      requestCameraPermission()
        .then(granted => {
          setCameraGranted(granted);
        })
        .catch((err) => {
          setCameraGranted(false);
        });
    }
    else
      setCameraGranted(true);

  }, [locationGranted, cameraGranted, locationAccurate]);

  const dispatch = useDispatch();

  const [loadingItem, setLoadingItem] = useState(-1);
  const [showConfirmationSection, setShowConfirmationSection] = useState(false);

  const goBackAndCompleteTask = () => {
    history.goBack();
    // HACK: a delay is needed here before clearing task data, as there's a
    // visual bug of showing duplicate home page content due to the redirection
    // below (redirect on refresh) as well as goBack() called above
    setTimeout(() => {
      dispatch(applicationActionCreators.completeTask(orderId, taskId));
    }, 1000);
  };

  const openItem = async (item: Item) => {
    setLoadingItem(item.id);
    let coords: GeolocationCoordinates | undefined;
    try {
      const pos = await getCurrentPosition();
      coords = pos.coords;
    } catch (e) { }
    dispatch(
      applicationActionCreators.startTaskItem(
        orderId,
        taskId,
        item.id,
        coords ? { latitude: coords?.latitude, longitude: coords?.longitude, accuracy: coords?.accuracy } : undefined)
    );

    dispatch<ApplicationStateKnownAction>({
      type: 'SET_LAST_VIEWED_POSITION',
      scrollTop: scrollContainerRef.current?.scrollTop ?? 0,
    });

    history.push(`/order/${orderId}/task/${taskId}/item/${item.id}`);

    if (item.itemType === ItemType.group) {
      // Start the children, won't somebody think of the children!?
      item.items.forEach(i => {
        dispatch(
          applicationActionCreators.startTaskItem(
            orderId,
            taskId,
            i.id,
            coords ? { latitude: coords?.latitude, longitude: coords?.longitude, accuracy: coords?.accuracy } : undefined
          )
        );
      });
    }
  };

  const responseExists = (itemId: number) => {
    const taskResponses = responses[orderId][taskId];
    const responseSubmission = taskResponses[itemId];
    if (!responseSubmission) return false;
    return 'response' in responseSubmission;
  };

  const allResponseExists = () => {
    const taskIsDone = schema.sections
      .filter(section => section.sectionType === SectionType.inspection)
      .flatMap(section => section.items)
      .every(item => responseExists(item.id));
    return taskIsDone;
  };

  const handleConfirmationItems = () => {
    const confirmationSection = schema.sections.filter(
      section => section.sectionType === SectionType.confirmation
    );
    if (confirmationSection.length === 0) {
      handleSubmit();
    } else {
      setShowConfirmationSection(true);
    }
  };

  const handleSubmit = () => {
    setShowConfirmationSection(false);
    dispatch(applicationActionCreators.submitTask(orderId, taskId));
  };

  // redirect to order page on refresh
  if (!submissionStatus[orderId][taskId])
    return <Redirect to={`/order/${orderId}`} />;

  // failed to submit
  if (
    submissionStatus[orderId] &&
    submissionStatus[orderId][taskId] &&
    submissionStatus[orderId][taskId].submissionError
  ) {
    return (
      <Page noBar>
        <PageMain center>
          <InfoStep
            image={warningIcon}
            text="Submission failed, please try again."
          />
        </PageMain>
        <Button
          color="blue"
          onClick={() =>
            dispatch(
              applicationActionCreators.dismissErrorSubmitTask(orderId, taskId)
            )
          }
        >
          Okay
        </Button>
      </Page>
    );
  }

  if (submissionStatus[orderId][taskId].isSubmitting) {
    return (
      <Page noBar>
        <div className={styles.submitting}>
          <Loading text="Please wait, sending..." />
        </div>
      </Page>
    );
  } else if (submissionStatus[orderId][taskId].submissionSuccess) {
    return (
      <Page noBar>
        <PageMain center>
          <InfoStep image={successIcon} text="Information successfully sent." />
        </PageMain>
        <Button color="green" onClick={goBackAndCompleteTask}>
          Done
        </Button>
      </Page>
    );
  }

  if (showConfirmationSection) {
    const section = schema.sections.find(
      section => section.sectionType === SectionType.confirmation
    )!;
    return (
      <ConfirmationSection
        section={section}
        onExit={() => setShowConfirmationSection(false)}
        onDone={handleSubmit}
      />
    );
  }

  // only show `inspection` sections
  const mainSections = schema.sections.filter(
    section => section.sectionType === SectionType.inspection
  );

  const renderIcon = (itemId: number) => {
    if (loadingItem === itemId) {
      return <Spinner small />;
    }
    return (responseExists(itemId) || readOnly) ? <ItemCheck /> : <ArrowRight />;
  };

  return (
    (!locationGranted || !locationAccurate || !cameraGranted) && !readOnly
      //true
      ? <DevicePermissions
        cameraRequested={!cameraGranted}
        locationRequested={!locationGranted}
        onGranted={() => {
          setLocationAccurate(true);
          setCameraGranted(true);
          setLocationGranted(true);
        }}
        locationAccuracyRequested={!locationAccurate}
      />
      : <Page title={schema.display} ref={scrollContainerRef}>
        <PageMain>
          {mainSections.map(section => (
            <React.Fragment key={section.id}>
              {mainSections.length > 1 && (
                <h2 className={styles.sectionName}>{section.name}</h2>
              )}
              {section.items.map(item => (
                <button
                  id={`scroll-${item.id}`}
                  key={item.id}
                  className={styles.item}
                  onClick={() => openItem(item)}
                >
                  <span>{item.display}</span>
                  {renderIcon(item.id)}
                </button>
              ))}
            </React.Fragment>
          ))}
        </PageMain>
        {!readOnly
          ? <Button
            color="green"
            onClick={handleConfirmationItems}
            disabled={!allResponseExists()}
          >
            Submit
          </Button>
          : <Button
            onClick={() => history.goBack()}
            color='green'>
            Done
          </Button>}
      </Page>
  );
};
