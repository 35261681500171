import { GpsData } from './gpsData';
import { GuidItem } from './guidItem';
import { ItemResponse } from './itemResponse';
import { Image } from './image';

interface Submission {
    guid: string;
    createdUtc: string;
    // updatedUtc?: string;
    gpsData?: GpsData;
}

export interface SurveySubmission extends Submission {
    organisationId: number;
    surveyId?: number;
    orderId: number;
    version: string;
    completedUtc?: string;
    scope?: SurveyScope;
    taskId?: number;
    responseGuids: GuidItem[];
    imageGuids: GuidItem[];
    responses: ResponseSubmission[];
}

export interface ResponseSubmission extends Submission {
    surveyGuid: string;
    itemId: number;
    response?: ItemResponse;
}

export interface ImageSubmission extends Submission {
    surveyGuid: string;
    itemId: number;
    image?: Image;
}

export enum SurveyScope {
    unknown = 0,
    perOrder = 1,
    perAsset = 2,
    perUser = 3,
}
