import { RootState } from "..";
import { ApplicationStateKnownAction } from "./actions";
import { appFetch } from './../../tools/fetch';
import { _apiUrl } from '../../settings';
import { ItemResponse } from '../../models/submissions/itemResponse';
import { ResponseSubmission, SurveySubmission } from "../../models/submissions";
import { ItemType } from "../../models/itemTypes";
import { getOrder, getEmail } from '../../api/order';
import { GpsData } from "../../models/submissions/gpsData";

type AppThunkAction<TAction> = {
    (dispatch: (action: TAction) => void, getState: () => RootState): void;
}

type KnownAction = ApplicationStateKnownAction;

export const applicationActionCreators = {
    startTask: (orderId: number, taskId: number, coords?: GpsData, cameraPermissionGranted?: boolean): AppThunkAction<KnownAction> => dispatch => {
        dispatch({
            type: 'APPLICATION_START_TASK',
            orderId,
            taskId,
            coords: coords ? {
                latitude: coords.latitude,
                longitude: coords.longitude,
                accuracy: coords.accuracy
            } : undefined,
            cameraPermissionGranted
        });
    },

    startTaskItem: (orderId: number, taskId: number, itemId: number, coords?: GpsData): AppThunkAction<KnownAction> => dispatch => {
        dispatch({
            type: 'APPLICATION_START_TASK_ITEM',
            orderId,
            taskId,
            itemId,
            coords,
        });
    },

    saveTaskItem: (orderId: number, taskId: number, itemId: number, itemResponse: ItemResponse): KnownAction => ({
        type: 'APPLICATION_SAVE_TASK_ITEM',
        orderId,
        taskId,
        itemId,
        itemResponse,
    }),

    saveTaskItemAndUpdateProgress: (orderId: number, taskId: number, itemId: number, itemResponse: ItemResponse): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch(applicationActionCreators.saveTaskItem(orderId, taskId, itemId, itemResponse));
        const url = `${_apiUrl}/progress/${getState().appState.orders[orderId].hash}`;
        const body = {
            organisationId: getState().appState.orders[orderId].organisationId,
            taskId,
            surveyItemId: itemId,
        };
        try {
            await appFetch(url, 'put', JSON.stringify(body));
        } catch (e) {
            console.log(`Failed to update progress: ${e}`);
        }
    },

    submitTask: (orderId: number, taskId: number, callback?: () => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({
            type: 'REQUEST_SUBMIT_TASK',
            orderId: orderId,
            taskId: taskId,
        });

        try {
            const url = `${_apiUrl}/send/${getState().appState.orders[orderId].hash}`;

            const submissionShell = getState().appState.submissions[orderId][taskId];
            const responses =
                (Object.values(getState().appState.responses[orderId][taskId]) as ResponseSubmission[])
                .filter(response => response.response && response.response.itemType !== ItemType.group)

            const submission: SurveySubmission = {
                ...submissionShell,
                responses: responses,
                responseGuids: responses.map(r => ({
                    guid: r.guid,
                    itemId: r.itemId,
                    date: r.createdUtc,
                })),
                imageGuids: responses
                    .filter(r => r.response && (r.response.image || (r.response.images && r.response.images.length)))
                    .flatMap(r => {
                        if (r.response!.image) {
                            return {
                                guid: r.response!.image.imageGuid,
                                itemId: r.itemId,
                                date: r.response!.image.createdUtc,
                            };
                        }
                        return r.response!.images!.map(i => ({
                            guid: i.imageGuid,
                            itemId: r.itemId,
                            date: i.createdUtc,
                        }));
                    }),
                completedUtc: new Date().toISOString(),
            };

            const json = JSON.stringify(submission);
            const response = await appFetch(url, 'POST', json);

            if (response.ok) {
                if (callback)
                    callback();

                // Yup, great
                dispatch({
                    type: 'COMPLETE_SUBMIT_TASK',
                    orderId: orderId,
                    taskId: taskId
                });
            }
            else {
                const errorText = await response.text();
                throw new Error(errorText);
            }
        }
        catch (e) {
            console.log(e);
            dispatch({
                type: 'ERROR_SUBMIT_TASK',
                orderId: orderId,
                taskId: taskId
            });
        }

    },

    dismissErrorSubmitTask: (orderId: number, taskId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({
            type: 'DISMISS_ERROR_SUBMIT_TASK',
            orderId: orderId,
            taskId: taskId,
        });
    },

    logout: (): AppThunkAction<KnownAction> => dispatch => {
        dispatch({
            type: 'APPLICATION_LOGOUT',
        })
    },

    refreshOrder: (orderId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'FETCH_ORDER_REQUEST' });

        try {
            const { hash } = getState().appState.orders[orderId];
            const email = await getEmail(hash);
            const order = await getOrder(email, hash.slice(0, 8));
            dispatch({ type: 'FETCH_ORDER_SUCCESS', order });
        } catch (e) {
            dispatch({ type: 'FETCH_ORDER_FAILURE' });
        }
    },

    completeTask: (orderId: number, taskId: number): AppThunkAction<KnownAction> => dispatch => {
        dispatch({ type: 'COMPLETE_TASK', orderId, taskId });
    }
};
