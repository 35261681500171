import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import styles from './livePhotoControl.module.scss';
import { Page, PageMain } from '../core/page';
import { Button } from '../core/button';
import { uploadLivePhotoImages } from '../../store/images/imagesSlice';
import { LivePhotoItem } from '../../models/schema/item';
import { LivePhotoItemResponse } from '../../models/submissions/itemResponse';
import addIcon from '../icons/icon-add-photo.svg';
import binIcon from '../icons/icon-trash-sm.svg';
import { useDispatch } from 'react-redux';
import { Image } from '../../models/submissions/image';
import { useImages, useImageStatusV2 } from '../../store/images/hooks';
import { ItemType } from '../../models/itemTypes';
import { Spinner } from '../core/spinner';

interface Props {
  inGroup?: boolean;
  item: LivePhotoItem;
  onExit: () => void;
  onConfirm: (data: LivePhotoItemResponse) => void;
  response?: LivePhotoItemResponse;
}

type Params = {
  orderId: string;
  taskId: string;
  itemId: string;
};

type FacingMode = 'environment' | 'user';

const MaxVideoWidth = 800;

const FPS = 10;

export const LivePhotoControl = ({
  item,
  response,
  onConfirm,
  onExit,
}: Props) => {
  const params = useParams<Params>();
  const orderId = parseInt(params.orderId);
  const taskId = parseInt(params.taskId);
  const itemId = parseInt(params.itemId);
  const itemFacingMode = item.facingMode == "User" ? 'user' : 'environment';

  const dispatch = useDispatch();

  const [facingMode, setFacingMode] = useState<FacingMode>(itemFacingMode);
  const [showCamera, setShowCamera] = useState(false);
  const [streamIsPlaying, setStreamIsPlaying] = useState(false);

  const [snapping, setSnapping] = useState(false);
  const [timerDisplay, setTimerDisplay] = useState(0);
  const [images, setImages] = useState<Image[]>(response?.images ?? []);
  const [base64Urls, setBase64Urls] = useState<string[]>([]);

  const imageData = useImages(images);

  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');

  const imageStatus = useImageStatusV2(images[0]?.imageGuid);

  const streamRef = useRef<MediaStream | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const initStream = async (facingMode: FacingMode) => {
    setFacingMode(facingMode);

    if (streamRef.current && streamRef.current.active) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    setStreamIsPlaying(false);

    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { facingMode },
      });
      setShowCamera(true);
    } catch (err) {
      console.log(err);
    }
  };

  // wait until `showCamera` is set (and hence video element is mounted) before displaying the track
  useEffect(() => {
    if (showCamera) {
      loadPreview();
    }
  }, [showCamera]);

  const loadPreview = async () => {
    const video = videoRef.current;
    if (!video) {
      console.log('video element missing');
      return;
    }

    video.srcObject = streamRef.current;
    video.width =
      window.innerWidth > MaxVideoWidth ? MaxVideoWidth : window.innerWidth;
    const trueVideoHeight =
      video.videoHeight / (video.videoWidth / video.width);
    const availableHeight = window.innerHeight - 185; // minus height of title and controls
    video.height =
      availableHeight > trueVideoHeight ? trueVideoHeight : availableHeight;

    await video.play();

    setStreamIsPlaying(true);
  };

  const endStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    setStreamIsPlaying(false);
    setShowCamera(false);
  };

  const switchCamera = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoInputs = devices.filter(device => device.kind === 'videoinput');
    if (videoInputs.length === 1) {
      console.log('only one camera');
      return;
    }

    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject = null;
    }
    await initStream(facingMode === 'environment' ? 'user' : 'environment');
    loadPreview(); // this is called manually here as `showCamera` is already set
  };

  const snapFrames = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext('2d');
    if (!context) return;
    const video = videoRef.current;
    if (!video) return;

    canvas.width = video.width;
    canvas.height = video.height;

    setSnapping(true);

    const totalFrames = item.duration * FPS;
    let currFrame = 1;
    const frames: string[] = [];

    const intervalId = setInterval(() => {
      context.drawImage(video, 0, 0, video.width, video.height);
      const base64Url = canvas.toDataURL('image/jpeg', 0.75);
      frames.push(base64Url);
      currFrame++;

      if (currFrame > totalFrames) {
        clearInterval(intervalId);
        endCapture(frames);
      }
    }, 1000 / FPS);
  };

  const endCapture = (frames: string[]) => {
    setSnapping(false);
    setTimerDisplay(0);
    setBase64Urls(frames);

    const images = frames.map((base64Url, i) => ({
      Type: 'Image' as const,
      imageGuid: uuid(),
      createdUtc: new Date().toISOString(),
      sequence: i,
      data: base64Url.split(',')[1],
    }));

    setImages(images);
  };

  useEffect(() => {
    if (snapping && timerDisplay === 0) {
      let timer = item.duration;
      setTimerDisplay(timer);
      const intervalId = setInterval(() => {
        setTimerDisplay(--timer);

        if (timer === 0) {
          clearInterval(intervalId);
        }
      }, 1000);
    }
  }, [item.duration, snapping, timerDisplay]);

  const retake = () => {
    setImages([]);
    setBase64Urls([]);
  };

  const upload = () => {
    setIsUploading(true);
    dispatch(uploadLivePhotoImages(orderId, taskId, itemId, images));
  };

  // on upload done/error
  useEffect(() => {
    if (isUploading && imageStatus === 'error') {
      setIsUploading(false);
      setError('An error has occured, please try again.');
    } else if (isUploading && imageStatus === 'done') {
      setIsUploading(false);
      endStream();
    }
  }, [isUploading, imageStatus, images, onConfirm]);

  const deleteImages = () => {
    setImages([]);
    setBase64Urls([]);
  };

  const saveResponse = () => {
    onConfirm({
      Type: 'Inspections.LivePhotoItemResponse',
      itemType: ItemType.livePhoto,
      images: images,
    });
  };

  return showCamera ? (
    <div className={styles.page}>
      <div className={styles.title}>
        <h1>{item.display}</h1>
      </div>
      <div className={styles.preview}>
        {error && (
          <div className={styles.error}>
            <img src="/images/warning.svg" alt="Error" />
            <p>{error}</p>
          </div>
        )}
        <video muted playsInline ref={videoRef}>
          Video stream not available.
        </video>
        <canvas ref={canvasRef} />
        {timerDisplay && <div className={styles.timer}>{timerDisplay}</div>}
        {streamIsPlaying && (
          <div className={styles.message}>
            <p>{item.message}</p>
          </div>
        )}
        {base64Urls.length > 0 && (
          <img className={styles.previewFrame} src={base64Urls[0]} alt="" />
        )}
      </div>
      <div className={styles.controls}>
        {base64Urls.length > 0 ? (
          <>
            <button
              className={styles.back}
              onClick={() => retake()}
              disabled={isUploading}
            >
              <img src="/images/camera-back.svg" alt="Back" />
            </button>
            <button
              className={styles.confirm}
              onClick={() => upload()}
              disabled={isUploading}
            >
              {isUploading ? (
                <Spinner small color="#1e3e5f" />
              ) : (
                <img src="/images/camera-check.svg" alt="Confirm" />
              )}
            </button>
          </>
        ) : (
          <>
            <button
              className={styles.cancel}
              onClick={() => endStream()}
              disabled={snapping}
            >
              Cancel
            </button>
            <button
              className={styles.snap}
              onClick={() => snapFrames()}
              disabled={!streamIsPlaying || snapping}
            >
              {/* eslint-disable-next-line */}
              <img src="/images/camera-snap.svg" alt="Take photo" />
            </button>
            <button
              className={styles.switch}
              onClick={() => switchCamera()}
              disabled={!streamIsPlaying || snapping}
            >
              <img src="/images/camera-switch.svg" alt="Switch camera" />
            </button>
          </>
        )}
      </div>
    </div>
  ) : (
    <Page title={item.display} onBack={onExit}>
      <PageMain>
        {imageData.length > 0 ? (
          <div className={cn(styles.tile, styles.previewImage)}>
            <img src={imageData[0].url} alt="" />
            <button className={styles.delete} onClick={() => deleteImages()}>
              <img src={binIcon} alt="Delete" />
            </button>
          </div>
        ) : (
          <div
            role="button"
            onClick={() => initStream(facingMode)}
            className={styles.tile}
          >
            <img src={addIcon} alt="" />
            <p>Take a live photo</p>
          </div>
        )}
      </PageMain>
      <Button
        color="green"
        onClick={() => saveResponse()}
        disabled={base64Urls.length === 0 || imageData.length === 0}
      >
        Confirm
      </Button>
    </Page>
  );
};
