import React, { useState } from 'react';
import { Page, PageMain } from '../core/page';
import { Button } from '../core/button';
import { InfoStep } from './infoStep';
import { ImageUpload } from '../core/imageUpload';
import { ImageViewer } from '../core/imageViewer';
import { Input } from '../core/input';
import { useImageStatus } from '../../store/images/hooks';
import { ItemType } from '../../models/itemTypes';
import { VerifyOdometerItem } from '../../models/schema/item';
import { VerifyOdometerItemResponse } from '../../models/submissions/itemResponse';

type Props = {
  item: VerifyOdometerItem;
  response?: VerifyOdometerItemResponse;
  onExit: () => void;
  onConfirm: (response: VerifyOdometerItemResponse) => void;
  readOnly?: boolean;
};

enum Steps {
  Info,
  Photo,
  Summary,
}

export const VerifyOdometerControl = (props: Props) => {
  const { item, response, onExit, onConfirm, readOnly } = props;

  const [step, setStep] = useState(response ? Steps.Summary : Steps.Info);

  // response
  const [image, setImage] = useState(response?.image);
  const [reading, setReading] = useState(response?.reading?.toString() ?? '');

  const imageIsValid = useImageStatus(image);

  const handleBack = () => {
    if (step === Steps.Info || response) {
      onExit();
    } else {
      setStep(step - 1);
    }
  };

  const handleSave = () =>
    onConfirm({
      Type: 'Inspections.VerifyOdometerItemResponse',
      itemType: ItemType.verifyOdometer,
      image,
      reading: parseInt(reading),
    });

  const getStep = () => {
    switch (step) {
      case Steps.Info:
        return (
          <>
            <PageMain center>
              <InfoStep
                image="/images/odometer.svg"
                text="Take a photo of the odometer of your vehicle. Please ensure odometer reading is visible."
              />
            </PageMain>
            <Button color="blue" onClick={() => setStep(Steps.Photo)}>
              Start
            </Button>
          </>
        );
      case Steps.Photo:
        return (
          <>
            <PageMain>
              <ImageUpload
                label="Photo of odometer"
                images={image ? [image] : []}
                itemId={item.id}
                onChange={images => setImage(images[0])}
                onDelete={() => setImage(undefined)}
                readOnly={readOnly}
              />
            </PageMain>
            <Button
              color="green"
              onClick={() => setStep(Steps.Summary)}
              disabled={!imageIsValid}
            >
              Next
            </Button>
          </>
        );
      case Steps.Summary:
        return (
          <>
            <PageMain>
              <ImageViewer
                images={image ? [image] : []}
                onClick={() => setStep(Steps.Photo)}
              />
              <Input
                label="Odometer"
                type="number"
                pattern="\d*" // for iOS Safari to use a numpad keyboard
                value={reading}
                onChange={e => setReading(e.target.value)}
                disabled={readOnly}
              />
            </PageMain>
            <Button color="green" onClick={handleSave} disabled={readOnly}>
              Confirm
            </Button>
          </>
        );
      default:
        return <p>Oops. Something went wrong. Try reloading the app.</p>;
    }
  };

  return (
    <Page title={item.display} onBack={handleBack}>
      {getStep()}
    </Page>
  );
};
