import React from 'react';
import cn from 'classnames';
import styles from './button.module.scss';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color: 'blue' | 'green' | 'outline';
  marginTop?: string | number; // default is 80px
};

export const Button: React.FC<Props> = props => {
  const { color, marginTop, children, ...attr } = props;

  return (
    <button
      className={cn(styles.button, styles[color])}
      style={{ marginTop }}
      {...attr}
    >
      {props.children}
    </button>
  );
};
